import React, { Component } from 'react'
import '../App.css';

export default class footer extends Component {
    render() {
        return (
            <div id="footer">
                <p id="footerText">© 2021-2024. All Rights Reserved</p>
            </div>
        )
    }
}