import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import img from "./certificateLogo.jpg";

const renderRichText = (element, yPosition, doc, pageWidth, margin) => {
  const classList = element.className.split(/\s+/);
  const isCenter = classList.includes("ql-align-center");
  const indentLevel = classList.find((cls) => cls.startsWith("ql-indent-"));
  const indent = indentLevel ? parseInt(indentLevel.split("-")[2]) * 10 : 0;

  let fontSize;
  switch (element.tagName.toLowerCase()) {
    case "h1":
      fontSize = 16;
      break;
    case "h2":
      fontSize = 14;
      break;
    case "h3":
      fontSize = 12;
      break;
    case "h4":
      fontSize = 10;
      break;
    case "h5":
      fontSize = 9;
      break;
    case "p":
    default:
      fontSize = 5;
  }

  doc.setFontSize(fontSize);
  doc.setFont("helvetica", "normal");

  const renderTextWithStyles = (node, x, y) => {
    if (node.nodeType === Node.TEXT_NODE) {
      doc.setTextColor(0, 0, 0); // Set text color to black for all text nodes
      doc.text(node.textContent, x, y);
      return doc.getTextWidth(node.textContent);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      let width = 0;
      const computedStyle = getComputedStyle(node);
      const color = node.style.color || computedStyle.color || "#000000";
      const bgColor =
        node.style.backgroundColor || computedStyle.backgroundColor;

      // Always set text color to black
      doc.setTextColor(0, 0, 0);

      if (bgColor && bgColor !== "transparent") {
        const rgb = bgColor.match(/\d+/g);
        if (rgb && rgb.length === 3) {
          doc.setFillColor(
            parseInt(rgb[0]),
            parseInt(rgb[1]),
            parseInt(rgb[2])
          );
          doc.rect(
            x,
            y - fontSize * 0.75,
            doc.getTextWidth(node.textContent),
            fontSize,
            "F"
          );
        }
      }

      for (let child of node.childNodes) {
        width += renderTextWithStyles(child, x + width, y);
      }
      return width;
    }
    return 0;
  };
};

export const generateCourseLetter = (
  firstname,
  spiritualname,
  lastname,
  certificateDate,
  courseLetterBody
) => {
  console.log("certificate date", certificateDate);

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} ${months[monthIndex]} ${year}`;
    console.log("formatted date", formattedDate);
    return formattedDate;
  };

  const doc = new jsPDF("portrait");
  doc.addFont(
    "LucidaHandwritingItalic",
    "Lucida Handwriting",
    "italic",
    "StandardEncoding",
    "base64-encoded-string"
  );
  doc.addImage(img, "JPEG", 10, 10, 35, 35);

  doc.setFontSize(20);
  doc.setFont("helvetica", "bolditalic");
  doc.text("Spirit of Truth", 75, 24, null, null, "center");

  doc.setFontSize(18);
  doc.setFont("helvetica", "bolditalic");
  doc.text("Native American Church", 52, 32); 

  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`Date: ${formatDate(certificateDate)}`, 15, 63);

  doc.text("", 15, 60); // Empty line after Date

  const fullName = `${firstname} '${spiritualname}' ${lastname},`;
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`Dear ${fullName}`, 15, 80);

  const courseLetterBodyElement = document.createElement("div");
  courseLetterBodyElement.innerHTML = courseLetterBody;
  courseLetterBodyElement.style.width = "600px";
  courseLetterBodyElement.style.padding = "10px";
  courseLetterBodyElement.style.paddingLeft = "0px";
  courseLetterBodyElement.style.color = "black";

  const allTextElements = courseLetterBodyElement.querySelectorAll("*");
  allTextElements.forEach((el) => {
    el.style.color = "black";
  });

  document.body.appendChild(courseLetterBodyElement);

  html2canvas(courseLetterBodyElement, {
    useCORS: true,
    scale: 4,
    backgroundColor: null,
    onclone: (clonedDoc) => {
      const clonedElement = clonedDoc.body.querySelector("div");
      clonedElement.style.color = "black";
      const allClonedElements = clonedElement.querySelectorAll("*");
      allClonedElements.forEach((el) => {
        el.style.color = "black";
      });
    },
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 180;
    const pageHeight = doc.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 90;

    doc.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
    heightLeft -= pageHeight - position - 15;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight + 15;
      doc.addPage();
      doc.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - 15;
    }

    doc.setFontSize(16);
    doc.setFont("Lucida Handwriting", "italic");
    doc.text("Man Found Standing", 15, pageHeight - 50);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Sincerely,", 15, pageHeight - 60);

    doc.text(
      "Man Found Standing, Principal Medicine Chief",
      15,
      pageHeight - 40
    );
    doc.text(
      "Governing Laws: UDHR (United Nations Declaration),",
      15,
      pageHeight - 35
    );
    doc.text(
      "U.S. Constitution, NAFERA (Native American Free Exercise",
      15,
      pageHeight - 30
    );
    doc.text("of Religion Act, 1993)", 15, pageHeight - 25);

    doc.text("", 15, pageHeight - 20); // Empty line before PO Box
    doc.text(
      "PO Box 2045  Ava, MO 65608",
      105,
      pageHeight - 15,
      null,
      null,
      "center"
    );

    doc.save(`cert.pdf`);
    document.body.removeChild(courseLetterBodyElement);
  });
};
