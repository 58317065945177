import jsPDF from "jspdf";
import img from "./certificateLogo.jpg";

export const ViewCourseCertificate = (
  firstname,
  spiritualname,
  lastname,
  certificateDate,
  certificateBody
) => {
  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  const calculateCenteredTextX = (text, doc) => {
    const textWidth = doc.getTextWidth(text);
    const pageWidth = doc.internal.pageSize.getWidth();
    return (pageWidth - textWidth) / 2;
  };

  const doc = new jsPDF("landscape");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 20;

  doc.addImage(img, "PNG", (pageWidth - 40) / 2, 10, 40, 40);

  // Title Text
  doc.setFontSize(16);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(0, 0, 0); // Ensure black color for title
  const titleText =
    "The Council Committee on New Membership of the Spirit of Truth Native American Church \n pon recommendation and by virtue of the authority vested in them, certify herewith . . .";
  const titleLines = doc.splitTextToSize(titleText, pageWidth - 2 * margin);
  titleLines.forEach((line, index) => {
    doc.text(line, calculateCenteredTextX(line, doc), 60 + index * 6);
  });

  // Full Name
  const fullName = `${firstname} '${spiritualname}' ${lastname}`;
  doc.setFontSize(28);
  doc.setFont("helvetica", "bolditalic");
  doc.setTextColor(0, 0, 0); // Ensure black color for name
  doc.text(fullName, calculateCenteredTextX(fullName, doc), 80);

  // Certificate Body
  const renderRichText = (element, yPosition, doc, pageWidth, margin) => {
    const classList = element.className.split(/\s+/);
    const isCenter = classList.includes("ql-align-center");
    const indentLevel = classList.find((cls) => cls.startsWith("ql-indent-"));
    const indent = indentLevel ? parseInt(indentLevel.split("-")[2]) * 10 : 0;

    let fontSize;
    switch (element.tagName.toLowerCase()) {
      case "h1":
        fontSize = 20;
        break;
      case "h2":
        fontSize = 18;
        break;
      case "h3":
        fontSize = 16;
        break;
      case "h4":
        fontSize = 14;
        break;
      case "p":
        fontSize = 12;
        break;
      default:
        fontSize = 12;
    }

    doc.setFontSize(fontSize);
    doc.setFont("helvetica", "normal");

    const renderTextWithStyles = (node, x, y) => {
      if (node.nodeType === Node.TEXT_NODE) {
        doc.text(node.textContent, x, y);
        return doc.getTextWidth(node.textContent);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        let width = 0;
        const color =
          node.style.color || getComputedStyle(node).color || "black";
        const bgColor =
          node.style.backgroundColor || getComputedStyle(node).backgroundColor;

        doc.setTextColor(color);
        if (bgColor && bgColor !== "transparent") {
          const rgb = bgColor.match(/\d+/g);
          if (rgb && rgb.length === 3) {
            doc.setFillColor(
              parseInt(rgb[0]),
              parseInt(rgb[1]),
              parseInt(rgb[2])
            );
            doc.rect(
              x,
              y - fontSize * 0.75,
              doc.getTextWidth(node.textContent),
              fontSize,
              "F"
            );
          }
        }

        for (let child of node.childNodes) {
          width += renderTextWithStyles(child, x + width, y);
        }
        return width;
      }
      return 0;
    };

    const text = element.textContent.trim();
    const availableWidth = pageWidth - 2 * margin - indent;
    const words = text.split(/\s+/);
    let line = "";
    let lineWidth = 0;

    words.forEach((word, index) => {
      const wordWidth = doc.getTextWidth(word + " ");
      if (lineWidth + wordWidth > availableWidth) {
        let xPosition = isCenter
          ? calculateCenteredTextX(line, doc)
          : margin + indent;
        renderTextWithStyles(element, xPosition, yPosition);
        yPosition += fontSize * 0.5;
        line = word + " ";
        lineWidth = wordWidth;
      } else {
        line += word + " ";
        lineWidth += wordWidth;
      }

      if (index === words.length - 1) {
        let xPosition = isCenter
          ? calculateCenteredTextX(line, doc)
          : margin + indent;
        renderTextWithStyles(element, xPosition, yPosition);
        yPosition += fontSize * 0.5;
      }
    });

    return yPosition + 2;
  };

  // Parse the HTML content
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(certificateBody, "text/html");
  const body = htmlDoc.body;

  let yPosition = 90;
  for (let child of body.children) {
    yPosition = renderRichText(child, yPosition, doc, pageWidth, margin);
  }

  // Reset text color to black for the rest of the document
  doc.setTextColor(0, 0, 0);

  doc.addFont(
    "LucidaHandwritingItalic",
    "Lucida Handwriting",
    "italic",
    "StandardEncoding",
    "base64-encoded-string"
  );

  // Additional Text
  doc.setFontSize(16);
  doc.setFont("Lucida Handwriting", "italic");
  doc.text("Man Found Standing  ", margin, pageHeight - 30);

  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  doc.text(
    `Effective Date: ${formatDate(certificateDate)}`,
    pageWidth -
      margin -
      doc.getTextWidth(`Effective Date: ${formatDate(certificateDate)}`),
    pageHeight - 30
  );

  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text("Principal Medicine Chief", margin, pageHeight - 20);

  // Use a fallback approach for Lucida Handwriting
  doc.setFont("times", "italic");
  doc.text("Man Found Standing", margin, pageHeight - 15);

  const governingLaws = [
    "Governing Laws: UDHR (United Nations ",
    "Declaration), U.S. Constitution, NAFERA (Native",
    "American Free Exercise of Religion Act, 1993)",
  ];

  governingLaws.forEach((line, index) => {
    doc.text(
      line,
      pageWidth - margin - doc.getTextWidth(line),
      pageHeight - 20 + index * 5
    );
  });

  const filename = `certificate_${Math.random()}.pdf`;
  const pdfBlob = doc.output("blob", filename);
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const newTab = window.open(pdfUrl, "_blank");

  if (newTab) {
    newTab.focus();
  } else {
    console.error(
      "Unable to open new tab. Please check your browser settings."
    );
  }
};
