import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  CardActions,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useAuth } from "hooks/useAuth";
import { GetApp as DownloadIcon } from "@mui/icons-material";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import axios from "config/axios";

const SectionDetailView = ({ url }) => {
  const { courseId, moduleId, sectionId } = useParams();
  const [sectionDetails, setSectionDetails] = useState(null);
  const [sectionPdfs, setSectionPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { getPdfsofSection, getSectionById, downloadPdf } = useAuth();

  const fetchSectionDetails = async () => {
    setIsLoading(true);
    const response = await getSectionById(sectionId);
    if (response.success) setSectionDetails(response.section);
    const res = await getPdfsofSection(response.section._id);
    if (res.success) setSectionPdfs(res.pdfs);
    setIsLoading(false);
  };

  const downloadFile = async () => {
    try {
      console.log("Inside downloadFile");
      // Make a GET request to the backend API endpoint
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses/file/get-all-files`
      );
      console.log(response);
      if (!response.ok) {
        throw new Error("Failed to fetch file");
      }

      // Return the response body as a blob
      const blob = await response.blob();

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "demo"; // Set the file name

      // Append the <a> element to the document body
      document.body.appendChild(a);

      // Click the <a> element to trigger the download
      a.click();

      // Remove the <a> element from the document body
      document.body.removeChild(a);

      // Revoke the URL to release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error:", error);
      // Handle any errors that occur during the process
    }
  };

  useEffect(() => {
    fetchSectionDetails();
  }, [sectionId]);

  const renderPdfs = () => {
    if (!sectionPdfs || sectionPdfs.length === 0) return null;

    return (
      <div>
        <Typography variant="h6">Materials</Typography>
        <List>
          {sectionPdfs.map((pdf, index) => (
            <ListItem key={index} className="flex items-start w-[35%]">
              <ListItemText
                primary={`${index + 1}.${pdf.originalname}`}
                className="italic"
              />
              {/* <Button
              variant="contained"
              color="primary"
              // onClick={() => downloadPdf(pdf._id)}
              onClick={() => downloadFile()}
              className="rounded-xl"
            >
              Download
            </Button> */}
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}/api/courses/file/downloadpdf/${pdf._id}`}
                download="filename.pdf"
              >
                Download
              </a>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  if (!isLoading) {
    return (
      <Container className="w-full mt-5">
        <div className="flex justify-start items-center p-2">
          <Link to={`${url}/courses/enrolled/${courseId}/${moduleId}/sections`}>
            <Button
              variant="contained"
              className="mb-2 mt-2"
              startIcon={<FastRewindIcon />}
            >
              Back
            </Button>
          </Link>
        </div>

        {sectionDetails && (
          <Card>
            <CardContent className="rounded-3xl">
              <Typography variant="h3" gutterBottom>
                {sectionDetails.title}
              </Typography>
              {/* <Typography variant="body1" className="text-xl" gutterBottom>
                {sectionDetails.body}
              </Typography> */}
              <ReactQuill
                value={sectionDetails.body}
                modules={{ toolbar: false }}
                readOnly
              />
              {renderPdfs()}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  flexWrap: "wrap",
                }}
              >
                <iframe
                  width="560"
                  height="315"
                  src={sectionDetails.videoUrl}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </CardContent>
            {/* You can add additional actions or buttons in CardActions */}
            {/* <CardActions>
              <Button variant="contained" color="primary">
                Action
              </Button>
            </CardActions> */}
          </Card>
        )}
      </Container>
    );
  } else {
    return <Loader />;
  }
};

export default SectionDetailView;
