import React from "react";

import './ErrorScreen.scss';

export const ErrorScreen = ( { errorMessage, exxplanation} ) => {
    return(
       

<div id="main">
      <div class="fof">
            <h1>Only youtube videos are supported</h1>
      </div>
</div>


    )
}
 

