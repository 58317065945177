import React from "react";
import NavbarComp from "../components/navbar";
import { Container } from "react-bootstrap";

export default function Prejudices() {
  return (
    <div id="Top">
      <NavbarComp />
      <div
        className="container flex items-center justify-center"
        style={{ padding: 15 }}
      >
        <Container style={{ padding: 15 }}>
          <p id="RiseOfMedicalText" className=" mb-5 text-center">
            Clearing Up Propaganda
          </p>
          <span className=" font-medium text-black text-xl text-center"></span>
          <p id="paragraphStyle" className="p_text">
            Every day numerous religions, and the people in those religions,
            undergo blatant prejudice and lies propagated by others. People
            claiming they are followers of God, the Creator of the universe,
            purposefully go away from their holy teachings to harm others.
            Normally this harm is just by spreading propaganda and
            misinformation but other times violence occurs. This is not a
            teaching of the Creator and the witness of the Holy Spirit can
            easily confirm that. (One of our Church’s first educational courses
            will be about understanding the witness of the Holy Spirit in more
            detail.) For example, the Church just experienced an attack on our
            beliefs from someone who works for an organization that claims,
            “independent, impartial, fair, and unbiased in our reporting” and a
            “catalyst for good.” This attack was in the form of an news article
            that contained at least eleven wrong, blatantly misleading, and
            prejudiced propaganda against our religious organization.
            <br />
            <br />
            Those eleven so-called facts, and others that were questionable,
            could have easily been checked for truth or written in a way that
            was not misleading or prejudiced. This individual, who said he was a
            Christian, chose not to follow Yeshua’s teachings and chose to
            follow the “father of lies” probably thinking that they were doing a
            “good thing”. (Matthew 7:21-23)
          </p>
          {/* <p className=" ml-20 mr-20 text-red-400 text-justify italic">
            Matthew 16:16-18 (NIV) - 16 Simon Peter answered, “You are the
            Messiah, the Son of the living God.” 17 Jesus replied, “Blessed are
            you, Simon son of Jonah, for this was not revealed to you by flesh
            and blood, but by my Father in heaven. 18 And I tell you that you
            are Peter, and on this rock I will build my church, and the gates of
            Hades will not overcome it.
          </p> */}
          <p id="paragraphStyle" className="p_text">
            The Spirit of Truth Native American Church desires individuals to
            develop their relationship with the Creator and walk the Sacred Way
            as the Spirit guides. (James 4:8) It is only through this
            relationship that a person can truly see through the deceptions of
            this existence and know the truth. (John 8:32 and John 14:26) For
            clarity's sake, here is some direct information (more details about
            each topic can be found on this website) to counter or clear up some
            of the propaganda and untruths that were said about our religion.
          </p>
          <div className="ml-7">
            <p id="paragraphStyle" className="p_text">
              1. We are a legitimate religion and a legitimate Native American
              Church. We not only have the passed-on genealogical direct
              authority from multiple Tribal Chiefs but also meet the US
              Government's requirements of being a legitimate religion. (View
              our Authority Page as well as our IRS Recognition letter showing
              we are recognized as a legitimate religion.)
            </p>
            <p id="paragraphStyle" className="p_text">
              2. We do have people with non-Indian blood in positions of
              leadership. This is a funny prejudice concern since almost all
              religions are not based on blood or DNA heritage. For example, you
              can still be a Christian minister even though you don’t have
              Jewish ancestry. The false belief that blood heritage has to be
              involved to guide a Native American Church is not only against our
              ancestors' teachings but also goes against the teachings of the
              Spirit. Now because our church does have legitimate “Indian”
              authority, legally all members are considered “Indians” under the
              law, even without any DNA connection to any Native American
              ancestor. (More about this is in our legal education.)
            </p>
            <p id="paragraphStyle" className="p_text">
              3. The Spirit of Truth Native American Church does not strive to
              follow the path of other major or minor Native American Churches
              or organizations. Our ancestors taught, no matter if they were
              from North America, Central America, or South America, that there
              was not one particular spiritual belief or practice that was the
              absolute correct way. The Creator of all things created this
              Universe and everything in it. As a part of creation, it is our
              responsibility and duty to follow the Creator and learn to live in
              peace and harmony. How we do this is by understanding the
              Creator’s will for us through following the Holy Spirit. The
              Spirit will guide us on our own unique path. Members of the Church
              are encouraged to develop a personal relationship with the Creator
              and do their best to follow the Spirit in all things.
            </p>
            <p id="paragraphStyle" className="p_text">
              Because we recognize all people of planet Earth as a part of
              Creation, we understand that the Creator loves all people. The
              Spirit has taught and continues to teach all people from around
              the world according to their understanding and their readiness to
              be open to the truth. Truth is not only contained in our ancestral
              teachings from the Americas but also in other teachings found
              throughout the world. The Creator is constantly sharing, guiding,
              and directing people as they open their hearts and minds. People
              who believe they know the correct way or are not able or willing
              to let go of prejudices/beliefs will have a harder time
              discovering the truth. (2 Timothy 3:7)
            </p>
            <p id="paragraphStyle" className="p_text">
              To some, especially those stuck in religious dogma or prejudice,
              this may be considered a ‘spiritual collage’ but the Church
              encourages our members to follow the Creator’s truths no matter
              where they were found/shared. Members of the Church strive for the
              Creator to guide them along the path he wants them to travel on.
              Sadly because of closed-mindedness, many people will not look at
              or reject a truth just because it comes from a different culture.
              Our Church members are encouraged to not follow this practice but
              to continue to seek out knowledge and truth no matter where it is
              found in the Universe.
            </p>
            <p id="paragraphStyle" className="p_text">
              4. Scriptures, are generally considered to be any writing or book
              that has a spiritual, belief, or religious connection to it. For
              some religions, they are dogmatically adhered to and considered
              infallible whereas other religions hold to them as “nice teaching”
              that may or may not be founded in truth. The Spirit of Truth
              Native American Church is all about following the Spirit and
              getting a confirmation of the Spirit about what is true and what
              is not. Members develop their relationship with the Creator and
              seek the Spirit's guidance in walking their life path. We hold
              spiritual writings, from all around the world, which can contain
              many truths and those truths can be confirmed by the Holy Spirit
              and therefore be called Scripture. We also know that man is
              imperfect and the “father of lies” (John 8:44) is actively engaged
              in trying to deceive, confuse, and destroy the truth. That is why
              the Spirit’s confirmation is the only way we can truly know the
              path we are on is the one the Creator wants us to follow.
            </p>
            <p id="paragraphStyle" className="p_text">
              As one example, the Spirit of Truth Native American Church
              considers the Bible, a collection of texts written by a variety of
              different people over the ages that was first put together around
              400 years after the birth of Christ, as Scripture. We also know
              that the designs of the “father of lies” and his use of corrupt
              organizations and people, the Bible should be taken as truth only
              as the Holy Spirit guides and testifies. Many parts can be
              contradicting or unclear and taken without the Spirit's
              confirmation, it can lead many people to stray from the Way. (1
              Corinthians 14:33 and 1 Timothy 4:1)
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              For example, Hebrews 9:27 says, “Just as people are destined to
              die once, and after that to face judgment,” many Christians living
              today are raised to completely reject all the teachings of other
              religions that believe in multiple lives, like Buddhism. Even
              though Christianity for over 500 hundred years had many people
              believing in reincarnation, it wasn’t until 553 A.D. during the
              Second Council of Constantinople that the Catholic Church took a
              more aggressive stance. Even though reincarnation was not
              officially rejected at that meeting, Church leaders who were
              accused of teaching reincarnation had their writings banned. Even
              the story of Lazarus, where he died more than once, along with
              other bible verses, like Jeremiah 1:5 and John 9:1-5, shows there
              is more understanding to be had through the confirmation of the
              Holy Spirit about multiple lives and what it means to be “destined
              to die once”.
            </p>
            <p id="paragraphStyle" className="p_text">
              Individuals must walk their path with the Creator and have truth
              confirmed to them by the Spirit. It is only in having the Spirit’s
              confirmation that the truth be truly written upon the heart/soul.
              Man Found Standing has said, “No matter what you read, what I say
              to you, or what spiritual experiences you have it is your
              responsibility to find out from the Creator what is true. The
              Spirit will witness the truth and it will be written in your soul
              and you won’t be deceived unless you willingly reject the truth.
              Even if an Angel of Light were to appear before you and give you a
              message, I implore you to confirm it through the Spirit so you
              will not be deceived by Satan himself in disguise. (2 Corinthians
              11:14) It is only through the Spirit that, “You shall know the
              truth and the truth shall set you free. -John 8:32” That is why
              developing a personal relationship with the Creator is so vital.
            </p>

            <p id="paragraphStyle" className="p_text">
              5.The Spirit of Truth Native American Church is not against
              organizations or governments but against corruption, evil in high
              places (Ephesians 6:12), and forcing people to follow a particular
              path or belief system. (Yeshua exposed to the people the evil
              corruption in high places and because of that, he was assassinated
              by crucifixion.) The Spirit of Truth Native American Church and
              its members understand that properly run, without corruption,
              organizations and governments provide great benefits and quality
              of life for all involved. Man’s wisdom, no matter how well
              intended, will never be greater than the wisdom of the Creator and
              therefore strive to receive the confirmation of the Spirit when
              running our organization.
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              Example: For over eight months I, Man Found Standing, was
              dedicated to locating a property that would be used for our
              religious ceremonies. After hundreds of hours, I finally found a
              property that I loved. It had everything we would want or need.
              Because of the circumstances of how I found this property, I
              thought it was divinely inspired. Because of this I went ahead and
              made a verbal offer on the property without checking with the
              Spirit.
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              Now a couple of days later I had to speak with the Council and
              apologize. I said, “I was so excited about the land that I
              completely forgot to pray about it. I just prayed and received the
              message of, ‘Not now.’ So, I called the real estate agent and told
              them that I have to withdraw the offer.”
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              What was also confirmation about this correct path was one of the
              Council members spoke up and said, “I too was not feeling right
              about that purchase. I saw you were so excited about the land that
              I figured I would just support your decision. That is a good
              lesson for me to not just blindly follow without my own personal
              confirmation from the Spirit.” (1 Kings 13)
            </p>
            <p id="paragraphStyle" className="p_text">
              Church members should always strive to follow the law and work
              within the law to follow their religious beliefs. No matter where
              in the world we live, we work within the established laws of the
              land to follow our religion. We understand that in times past as
              well as in current times, there is corruption and evil in high
              places. We understand if we are not diligent this corruption can
              even overtake our organization.{" "}
            </p>
            <p id="paragraphStyle" className="p_text">
              Many people, and the organizations they control, will willfully
              follow Satan and have evil designs to harm or enslave the
              population. (History has shown that the people in control
              sometimes have created laws or situations that instill fear to
              manipulate the masses. They then will rush in with a “fix” at the
              price of the people losing some of their freedoms. As Johann von
              Goethe said, “The best slave is the one who thinks he is free.”)
              Because of the “evil in high places,” church members are
              encouraged to be active in society to assist in upholding the law.
              If any law tends to enslave, place one person in subjection to
              another, restrict the practice of our religion, or unjustly punish
              our religion members are encouraged to work within the law and
              morally accepted norms to legally direct positive change. Members
              strive to obey and follow the Spirit and walk the path the Creator
              guides them to. “For God has not given us a spirit of fear, but of
              power and of love and of a sound mind.” – 2 Timothy 1:7
            </p>
            <p id="paragraphStyle" className="p_text">
              6. Peyote is NOT the only exception for hallucinogenic substances
              to be used by Native Americans in religious ceremonies. That is
              the only hallucinogenic substance that was specifically cited in
              the American Indian Religious Freedom Act. It also mentioned the
              exceptions for “Animal Parts”, and “Other Plant Materials”. Also,
              many legal cases have already confirmed our legal right to use the
              Creator’s Sacraments in our bona fide religious ceremonies. (More
              about this is found in our legal education course.)
            </p>
            <p id="paragraphStyle" className="p_text">
              7. Mainstream Christianity takes the view that the Bible alone is
              the supreme authority for what Christians should believe and
              practice. Even though there are other Spirit-inspired records in
              existence, and the Bible is just a collection of a few of those
              records, they hold the Bible as the only infallible source of
              authority. Most people involved in mainstream Christianity hold to
              their chosen beliefs and study them over and over again but never
              receive true clarity of the truth from the Creator. (2 Timothy
              3:7)
            </p>
            <p id="paragraphStyle" className="p_text">
              The Spirit of Truth Native American Church does not hold that
              view. We hold the Spirit as the infallible source of authority. It
              is only through developing your relationship with the Creator that
              you can receive the Gifts of the Holy Spirit and truly distinguish
              truth from error.
            </p>
            <p id="paragraphStyle" className="p_text">
              Many Christian religions have come into existence because of
              different doctrinal interpretations of the Bible verses. Also,
              there are many confusing or contradicting verses in the Bible that
              religions just skip over or explain away as not holding value. It
              is only by gathering knowledge and getting a witness of the truth
              by the Spirit, that greater understanding can occur.
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              For example, in John 10:14-16 it says, “I am the good shepherd;
              and I know My sheep, and am known by My own. As the Father knows
              Me, even so I know the Father; and I lay down My life for the
              sheep. And other sheep I have which are not of this fold; them
              also I must bring, and they will hear My voice; and there will be
              one flock and one shepherd.” Yeshua is talking about his death
              sacrifice for his followers. He even says that “other sheep” or
              other people that are not a part of the people he was then
              teaching, would hear his voice and be saved as well. So, what
              other people did Yeshua teach?
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              Some Native American ancestral stories/records say that Yeshua
              came and visited the people in the Americas after his crucifixion.
              Other ancestral stories/records say that Yeshua also visited them
              even though they lived in different areas around the world. If
              this is true, it would explain what other sheep Yeshua is talking
              about.
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              The Spirit of Truth Native American Church holds all sacred
              writings from around the world that the Spirit confirms as
              scripture. The Spirit is the infallible source of authority, not
              the wisdom of men (1 Corinthians 1:25). Because of our belief that
              truth can also be found outside the Bible, and because we allow
              members to walk the Way as the Spirit directs, there has been much
              prejudice against our Church. Our simple doctrinal truths found in
              our Constitution can fit alongside many religions and it is
              perfectly acceptable to be a member of one religion as well as a
              member of the Spirit of Truth Native American Church. (All
              teachings our members follow should personally be confirmed to
              them by the Spirit.)
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              For example, the Book of Mormon, a book that was first published
              before the Church of Jesus Christ of Latter-Day Saints (Mormons)
              was established, is currently used by a few different religions,
              as scripture. Here is what Man Found Standing has said.
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              “I don’t require our members to believe the same way I do but yes,
              I do hold The Book of Mormon as scripture just as I hold many
              other texts. Even though it will contain, like other scriptures,
              errors from translation and human frailties, I have found the book
              to hold many truths that have been witnessed to me by the Holy
              Spirit. For example, it clarifies subjects that mainstream
              Christianity is confused about such as ‘other sheep’ and ‘infant
              baptism’. If you pray with an open heart, the Spirit will confirm
              the truthfulness found in all scripture. (James 1:5-6) I always
              find getting a witness directly from the Spirit about a new truth
              or understanding is quite exciting.
            </p>
            <p id="paragraphStyle" className="ml-5 p_text">
              Now, just because I believe in the truth found in The Book of
              Mormon does not mean I follow the doctrine of other religions
              using that book as scripture. The Church of Jesus Christ of
              Latter-day Saints (Mormons), usually will tell new investigators
              to follow the thought logic of. . .
            </p>
            <p id="paragraphStyle" className="ml-8 p_text">
              First, if The Book of Mormon is true and you gain a witness of it
              by the Spirit, then . . . Second, Joseph Smith must have had
              divine guidance to obtain and translate the book, then . . . .
              Third, he must be a prophet or some spiritual leader, then . . .
              Fourth, the church he was instructed to organize is true, then . .
              . Fifth, the church as it has continued from the time of Joseph
              Smith is still true.
            </p>
            <p id="paragraphStyle" className="p_text">
              I have found that just following that type of logic no matter what
              subject it follows to be incorrect. Just because some parts of the
              ‘logic train’ are true, does not mean you follow that path down
              wherever it takes you. Each step of the way, you must again get a
              confirmation of the truth by asking the Holy Spirit. It is only
              through the Spirit that you will be able to gain a witness of
              truth and see the untruth or errors, whether on purpose or by
              accident, that are introduced. Let the Spirit guide you on the
              path of truth he wants you to follow.
            </p>
            <p id="paragraphStyle" className="p_text">
              I have seen over time that leadership in organizations can fall
              into the temptation to control, even with good intentions. Because
              of the many human frailties, errors can also quickly accumulate
              over time to take one from the Way. Once again that is why it is
              so vital to continually receive confirmation from the Spirit as
              you travel down your path in life. My prayer is for our members to
              never let any organization or person, no matter what the position
              in the Church they hold, stand between them and their relationship
              with the Creator. Don’t take what I say, even as your Principal
              Medicine Chief, as truth until it has been confirmed to you
              through the Spirit. I had a wonderful witness from the Spirit
              about this truth when I first read 1 Kings 13.”
            </p>
            <p id="paragraphStyle" className="p_text">
              8. Even though the current monetary system is not the best
              solution and is ripe with corruption, it is the method the world
              chooses to engage. In the modern world, escaping from such a
              system with all its entanglements is hard. Just like other
              churches, the Spirit of Truth Native American Church owns
              properties and other assets. Those assets are used for church
              purposes. The Church’s main goal of accumulation of such assets is
              to assist members in leaving Babylon (Revelation 18:4). Sadly,
              many people are under the false belief that having wealth is
              somehow wrong or bad. This is not the case but what is done with
              the wealth is the real issue. The Creator owns everything and in
              his house/kingdom, there are many mansions. (John 14:2) If anyone
              has hangups on receiving abundance from the Creator, you may want
              to look at the current blockages that are stopping you from
              receiving. (Matthew 7:9-11)
            </p>
          </div>
          {/* <p className=" ml-20 mr-20 text-black text-justify italic">
            Note: Now my legal first name is Paul and I like my name. Now in
            other languages, my name gets translated. For example, Paul in
            Spanish is converted over to Pablo a name that I don’t like the
            sound of. (Just a personal preference.) Now when I travel down to
            Mexico, I prefer being called Paul rather than Pablo but I still
            answer to both and allow people the choice to what they call me.
            Just out of respect for the Messiah and his language of birth, our
            Church refers to Jesus as Yeshua. (I am positive he also does not
            mind having his name translated and allows people the choice of what
            they will call him.)
          </p>
          <p id="paragraphStyle" className="p_text">
            When Joseph found out that Mary was pregnant an angel appeared to
            him in a vision to tell him to name the boy Yeshua because he will
            save his people from their sins. (Matthew 1:20-21) Clearly, this
            shows the importance of Yeshua’s name and it is inseparably linked
            to him having the power and authority of the Messiah.
          </p>
          <p className=" ml-20 mr-20 text-red-400 text-justify italic">
            Acts 4:12 (NIV) - 12 Salvation is found in no one else, for there is
            no other name under heaven given to mankind by which we must be
            saved.
          </p>
          <p id="paragraphStyle" className="p_text">
            Many years after Yeshua’s resurrection the New Testament Books were
            written in Greek. In those translations, Yeshua was translated into
            Iēsous. Then many, many years later when the Greek was translated
            into English the Messiah’s name became Jesus.
          </p> */}
        </Container>
      </div>
    </div>
  );
}
