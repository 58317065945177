import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Loader from "components/Loader";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { visuallyHidden } from "@mui/utils";

import { useEffect, useState } from "react";
import { useAuth } from "hooks/useAuth";

function EnhancedTableHead({
  headCells,
  orderBy,
  order,
  tableName,
  onRequestSort,
  actions,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { user } = useAuth();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={"p-0 m-0 w-0 "}></TableCell>

        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <div
                className={`w-full flex justify-${headCell.align} text-white`}
              >
                {headCell.dontUseForOrder ? (
                  headCell.label
                ) : (
                  <>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      align={headCell.align}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </>
                )}
              </div>
            </TableCell>
          );
        })}

        {tableName == "User List" && (
          <TableCell>
            <div className={`w-full flex justify-center `}>
              <TableSortLabel>Status</TableSortLabel>
            </div>
          </TableCell>
        )}
        {/* {tableName != "User List" && user.role == "admin" && actions && (
          <TableCell>
            <div className={`w-full flex justify-center `}>
              <TableSortLabel>Actions</TableSortLabel>
            </div>
          </TableCell>
        )} */}
      </TableRow>
    </TableHead>
  );
}

export function EnhancedTable({
  url,
  actionsAdditionalFields = [],
  headers,
  startIndex,
  endIndex,
  tableName,
  rows,
  defaultOrder,
  setOrderOnParent,
  actions,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(defaultOrder || "createdAt");
  const [profiles, setProfiles] = useState(rows);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [donations, setDonations] = useState(rows);
  const { user } = useAuth();

  const filterRows = () => {
    if (tableName === "User List") {
      const handleSort = (data, order) => {
        if (!order) {
          return data;
        }
        return data.sort((a, b) => {
          const orderFactor = order === "asc" ? 1 : -1;
          const valueA = String(a[orderBy]).toLowerCase();
          const valueB = String(b[orderBy]).toLowerCase();
          return valueA.localeCompare(valueB) * orderFactor;
        });
      };

      const sortedAndFilteredRows = handleSort(
        rows.filter((row) => {
          const {
            firstname,
            middlename,
            lastname,
            spiritualname,
            email,
            phone,
            addressline1,
            addressline2,
            state,
            city,
            zipcode,
            country,
            dralawalletaddress,
          } = row;
          const searchTerm = searchQuery.trim().toLowerCase();
          return (
            (firstname && firstname.toLowerCase().includes(searchTerm)) ||
            (middlename && middlename.toLowerCase().includes(searchTerm)) ||
            (lastname && lastname.toLowerCase().includes(searchTerm)) ||
            (spiritualname &&
              spiritualname.toLowerCase().includes(searchTerm)) ||
            (email && email.toLowerCase().includes(searchTerm)) ||
            (addressline1 && addressline1.toLowerCase().includes(searchTerm)) ||
            (addressline2 && addressline2.toLowerCase().includes(searchTerm)) ||
            (state && state.toLowerCase().includes(searchTerm)) ||
            (city && city.toLowerCase().includes(searchTerm)) ||
            (zipcode && zipcode.toLowerCase().includes(searchTerm)) ||
            (country && country.toLowerCase().includes(searchTerm)) ||
            (dralawalletaddress &&
              dralawalletaddress.toLowerCase().includes(searchTerm)) ||
            (phone && phone.toString().toLowerCase().includes(searchTerm))
          );
        }),
        order
      );

      setProfiles(sortedAndFilteredRows);
    } else {
      const filterDonations = (donations, searchQuery, order) => {
        const handleSort = (data, order) => {
          if (!order) {
            return data;
          }
          return data.sort((a, b) => {
            const orderFactor = order === "asc" ? 1 : -1;
            const valueA = String(a[orderBy]).toLowerCase();
            const valueB = String(b[orderBy]).toLowerCase();
            return valueA.localeCompare(valueB) * orderFactor;
          });
        };

        const sortedAndFilteredDonations = handleSort(
          donations.filter((donation) => {
            const searchTermLower = searchQuery.trim().toLowerCase();
            return (
              String(donation.amount).toLowerCase().includes(searchTermLower) ||
              (donation.currency &&
                donation.currency.toLowerCase().includes(searchTermLower)) ||
              (donation.purchasedItems &&
                donation.purchasedItems
                  .toLowerCase()
                  .includes(searchTermLower)) ||
              (donation.status &&
                donation.status.toLowerCase().includes(searchTermLower)) ||
              (donation.user &&
                donation.user.toLowerCase().includes(searchTermLower))
            );
          }),
          order
        );

        setDonations(sortedAndFilteredDonations);
      };
    }
  };
  // useEffect(() => {
  //   setOrderOnParent({ order: order === "asc" ? 1 : -1, orderBy: orderBy });
  // }, [order, orderBy]);

  useEffect(() => {
    filterRows();
  }, [searchQuery, order, rows]);

  useEffect(() => {
    filterRows();
  }, [order]);

  const [dense, setDense] = useState(false);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  const handleRequestSort = (event, property) => {
    // Set order based on current value, ensuring consistent toggling
    setOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setOrderBy(property);
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  if (!isLoading) {
    return (
      <>
        {/* Search Bar */}
        {/* <Box mb={2} mt={2} className="rounded-md border border-gray-300">
          {user.role === "admin" &&
            tableName !== "List of Donations Made to Site" && (
              <TextField
                label="Search users"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                fullWidth
              />
            )}
        </Box> */}
        {/* Search Bar */}

        {!tableName || (
          <div className="userListBtn_parent">
            <button id="user_list_btn">{tableName}</button>
          </div>
        )}
        <hr className="mt-0" />

        {/* table start */}
        <Box sx={{ width: "100%" }} className="px-4 pb-3">
          <Paper sx={{ width: "100%", mb: 2 }}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  actionsAdditionalFields={actionsAdditionalFields}
                  order={order}
                  orderBy={orderBy}
                  headCells={headers}
                  tableName={tableName}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  actions={actions}
                />

                {tableName === "User List" ? (
                  <TableBody>
                    {profiles
                      .slice(startIndex - 1, endIndex - 1)
                      ?.map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell className={"p-0 m-0 w-0 "}></TableCell>

                            {headers.map((header) => {
                              // debugger;

                              if (header.formattingCallback) {
                                const formattingCallBack =
                                  header.formattingCallback;
                                return (
                                  <TableCell align="left">
                                    {row[header.id]
                                      ? formattingCallBack(row[header.id])
                                      : formattingCallBack(
                                          header.defaultValue
                                        )}{" "}
                                    {header.additional_text && row[header.id]
                                      ? header.additional_text
                                      : ""}{" "}
                                  </TableCell>
                                );
                              }

                              if (header.formattingCallbackV2) {
                                const formattingCallBack =
                                  header.formattingCallbackV2;
                                return (
                                  <TableCell align="left">
                                    {formattingCallBack(header.id, row)}{" "}
                                  </TableCell>
                                );
                              }

                              return (
                                <TableCell align="left">
                                  {row[header.id]
                                    ? row[header.id]
                                    : header.defaultValue}{" "}
                                  {header.additional_text && row[header.id]
                                    ? header.additional_text
                                    : ""}{" "}
                                </TableCell>
                              );
                            })}

                            <TableCell
                              className={"flex items-center justify-center"}
                              align="center"
                            >
                              {actions({
                                url,

                                id: row.user,
                                ...Object.assign(
                                  {},
                                  ...actionsAdditionalFields.map((field) => {
                                    const object = {};
                                    object[field] = row[field];
                                    return object;
                                  })
                                ),
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    {profiles.slice(startIndex - 1, endIndex)?.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell className={"p-0 m-0 w-0 "}></TableCell>
                          {headers.map((header) => {
                            // debugger;
                            if (header.formattingCallback) {
                              const formattingCallBack =
                                header.formattingCallback;

                              return (
                                <TableCell align="left">
                                  {row[header.id]
                                    ? formattingCallBack(row[header.id])
                                    : formattingCallBack(
                                        header.defaultValue
                                      )}{" "}
                                  {header.additional_text && row[header.id]
                                    ? header.additional_text
                                    : ""}{" "}
                                </TableCell>
                              );
                            }

                            if (header.formattingCallbackV2) {
                              const formattingCallBack =
                                header.formattingCallbackV2;
                              return (
                                <TableCell align="left" key={header.id}>
                                  {row["donationDate"] !== null
                                    ? formattingCallBack("donationDate", row)
                                    : formattingCallBack(header.id, row)}
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell align="left">
                                {typeof row[header.id] === "number"
                                  ? `$${row[header.id]}`
                                  : ""}
                                {row[header.id] &&
                                typeof row[header.id] !== "number"
                                  ? row[header.id]
                                  : header.defaultValue}
                                {header.additional_text && row[header.id]
                                  ? header.additional_text
                                  : ""}
                              </TableCell>
                            );
                          })}

                          {(user.role === "admin" && !actions) || (
                            <TableCell
                              className={"flex items-center justify-center"}
                              align="center"
                            >
                              {actions({
                                url,
                                transactionId: row._id,
                                id: row.userId,
                                ...Object.assign(
                                  {},
                                  ...actionsAdditionalFields.map((field) => {
                                    const object = {};
                                    object[field] = row[field];

                                    return object;
                                  })
                                ),
                              })}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </>
    );
  } else {
    return <Loader />;
  }
}
