import "../UserViewDetails/UserViewDetails.css";
import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { UseSearchQuery } from "hooks/useSearchQuery";
import { PaginationComponent } from "features/courses/components/Paginitation";
import { EnhancedTable } from "components/SmartTable/SmartTable";
import { getUsersListForAdmin } from "services/userService";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Loader from "components/Loader";
import axios from "config/axios";
import { toast } from "react-toastify";

const actions = ({
  url,
  id,
  status,
  isProfileVerified,
  setIsProfileVerified,
}) => {
  const handleClick = async () => {
    const userId = id;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/verify-profile`,
        { userId }
      );
      setIsProfileVerified(!isProfileVerified);
      console.log("well done");
      toast.success("User profile was verified");
    } catch (error) {
      console.log("coulnt verify profile", error);
    }
  };
  return (
    <>
      <Button onClick={handleClick} variant="contained" className="bt_info">
        Verify
      </Button>

      <NavLink to={`${url}/users/verification/${id}`}>
        <Button
          style={{ marginLeft: "5px" }}
          variant="contained"
          className="bt_info"
        >
          Details
        </Button>
      </NavLink>
    </>
  );
};

const AdminVerifyUser = ({ url }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [values, setValues] = useState();

  const [isProfileVerified, setIsProfileVerified] = useState(false);
  const {
    search,
    bindFormValue,
    setOrderByField,
    sortingOrder,
    setCount,
    setPage,
  } = UseSearchQuery();
  // const [ isLoading, setIsLoading ] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchProfiles } = useAuth();

  const [rows, setRows] = useState([]);

  const [totalEntries, setTotalEntries] = useState(0); 

  useEffect(() => {
    initData();
  }, [isProfileVerified]);

  const initData = async () => {
    setIsLoading(true);
    // const response = await fetchProfiles();
    console.log("started");
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/auth/unverified-profiles`
    );

    console.log("length", response.data.profiles.length);
    setRows(response.data.profiles);
    setTotalEntries(response.data.profiles.length);
    setIsLoading(false);
  };

  const headers = [
    // {'label': 'User id', 'id':'_id', 'align':'start'},
    { label: "Last Name", id: "lastname", align: "start" },
    { label: "First Name", id: "firstname", align: "start" },
    { label: "Spiritual Namehh", id: "spiritualname", align: "start" },
    { label: "Email", id: "email", align: "start" },
    // {'label': 'Donation', 'id':'total_amount_donated', 'align':'start'},
    // {'label': 'Account status', 'id':'account_status', 'align':'start'},
    // {'label': 'User Type', 'id':'role', 'align':'start'},
  ];

  const handleShow = (event) => {
    event.preventDefault();
    const keys = Object.keys(values);

    for (const key of keys) {
      bindFormValue(key, values[key]);
    }
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    const name = prop;
    const value = event.target.value;
    const newObject = {};
    newObject[name] = value;

    setValues((current) => {
      return { ...current, ...newObject };
    });
  };

  const fetchUnverfiedUsers = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/auth/unverifed-users`
    );
    console.log("my response", response);
    setUserData(response);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="container-fluid">
        <div className="AdminDashboardContentPanel">
          <EnhancedTable
            // actions={actions}
            actions={(props) =>
              actions({ ...props, isProfileVerified, setIsProfileVerified })
            }
            actionsAdditionalFields={["account_status"]}
            url={url}
            tableName={"User List"}
            headers={headers}
            defaultOrder={"lastname"}
            rows={rows}
            startIndex={startIndex}
            endIndex={endIndex}
            setOrderOnParent={(state) => {
              sortingOrder(state.order);
              setOrderByField(state.orderBy);
            }}
          />

          {/* <PaginationComponent
              initTotalEntries={totalEntries}
              initItemsPerPageCount={5}
              initPageNumber={1}
              setParamsOnParent={({ page, itemsPerPageCount }) => {
                setPage(page);
                setCount(itemsPerPageCount);
              }}
            /> */}
          <PaginationComponent
            initTotalEntries={totalEntries}
            initItemsPerPageCount={10}
            startIndex={startIndex}
            endIndex={endIndex}
            setStartIndex={setStartIndex}
            setEndIndex={setEndIndex}
            initPageNumber={1}
            setParamsOnParent={({ page, itemsPerPageCount }) => {
              setPage(page);
              setCount(itemsPerPageCount);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminVerifyUser;
