import axios from "config/axios";
import { useAuth } from "hooks/useAuth";
import React, { useState, useEffect, useRef } from "react";

const PayPalPayment = ({
  initialAmount,
  courseId,
  handlePaypalSuccess,
  setUserOrderId,
  paymentSuccess,
  userOrderId,
  paymentStart,
  setPaymentStart,
  type,
  setPaymentSuccess,
}) => {
  const paypal = useRef(null);
  const [existingButton, setExistingButton] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const { user } = useAuth();
  const userId = user._id;
  console.log("type", type);

  useEffect(async () => {
    if (type === "Donation") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/transactions/make-donation`,
          { userId, orderId, initialAmount }
        );
        setUserOrderId(orderId);
        console.log("response", response);
        console.log("made the donaction");
      } catch (error) {
        console.log("cant make donation", error);
      }
    }
  }, [orderId]);

  useEffect(async () => {
    if (orderId && paymentStart) {
      try {
        console.log("orderid", orderId);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/transactions/buy-course`,
          { userId, orderId, initialAmount, courseId }
        );

        console.log("orderid", orderId);
        setPaymentStart(false);
        setPaymentSuccess(true);
        console.log("response", response);
        console.log("made the donaction");
        // handlePaypalSuccess();
      } catch (error) {
        console.log("cant make donation", error);
      }
    }
  }, [orderId]);

  useEffect(() => {
    if (existingButton) {
      existingButton.close();
    }

    const createPayPalOrder = async () => {
      try {
        const actions = window.paypal.Buttons({
          createOrder: (data, actions, err) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description: "Drala Spirit of Truth",
                  amount: {
                    currency_code: "USD",
                    value: initialAmount,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            setOrderId(order.id);
            console.log("order", order);
          },
          onError: (err) => {
            console.log("error", err);
          },
        });

        actions.render(paypal.current);
        setExistingButton(actions);
      } catch (error) {
        console.error("Error creating PayPal order:", error);
      }
    };

    createPayPalOrder();

    return () => {
      if (existingButton) {
        existingButton.close();
      }
    };
  }, [initialAmount]);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
};

export default PayPalPayment;
