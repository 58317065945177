import React from "react";
import { Container } from "react-bootstrap";
import NavbarComp from "../components/navbar";
import Footer from "../components/footer";
// import "../css/EthicalCode.css";

export default function EthicalCode() {
  return (
    <div id="Top">
      <NavbarComp />
      <Container className="ethical-code-container">
        <h1>Spirit of Truth Native American Church</h1>
        <h2>Ethical Code of Conduct</h2>
        <p className="update-info">(Updated in Council 07-08-2024)</p>
        <h3>Introduction</h3>
        <p>
          The Spirit of Truth Native American Church is a group of members who
          strive to live and practice the Sacred Healing Way of the Ancient
          Ancestors and follow the teachings of Yeshua. Each Spirit of Truth
          Native American Church member is duly adopted into the Spiritual
          Family of the Principal Medicine Chief and has made Covenant
          Declarations that complete the requirements to join the church and be
          inducted into the Ministry. The Sacred Healing Way is a system of
          teachings and practices that has been developed and approved under the
          direction of the Principal Medicine Chief and appropriate councils,
          for the creation and training of the Church's members and ministers of
          Medicine Men and Medicine Women.
        </p>
        <p>
          Spirit of Truth Native American Church members are committed to the
          use of their knowledge, experience, and training to enhance and
          further the condition of individuals, families, communities, all of
          society, and the planet. Our Medicine Men and Medicine Women respect
          and protect the civil and human rights of others and their freedom of
          inquiry and spiritual experience. They strive to assist the public and
          other members of the Church to understand and learn about the Creator,
          creation, healing, freedom of health care, informed judgment, the
          self-empowered choice regarding religion, Spirit, health, ceremony,
          education, and so forth. In doing so, they perform many functions
          including Healer, Teacher, Support Person, Consultant, Counselor,
          Spiritual Guide, Interventionist, Minister, Clergy, Role Model, and so
          forth.
        </p>
        <p>
          Medicine Men and Medicine Women should understand that by the very
          virtue of their position and chosen way of life, they are models for
          society and their way of administering their healing services. By
          living within a code created by the Spirit and by ethical
          consideration, they are doing all that is reasonably within their
          power to perform, teach, and heal.
        </p>
        <p>
          The Spirit of Truth Native American Church Ethical Code of Conduct is
          intended to provide guides to encompass most scenarios found in
          Healing. Found in it are the guides to establish and protect the
          safety and welfare of individuals, communities, society, and the
          world. The goal of the Ethical Code of Conduct is to educate its
          Members and Ministers and to inform them that actions can result in
          outcomes that sometimes cannot be seen before the fact. All members
          should be dedicated to an Ethical Code of Conduct so the outcomes of
          their actions will produce much more positive results.
        </p>
        <h3>Ethical Code of Conduct General Guidelines</h3>
        <p>
          These general guidelines for the Ethical Code of Conduct assist to
          inspire the Healing Community and the World toward the spiritual
          understanding of the Creator, Yeshua, and the Holy Spirit and assist
          the physical welfare of all. The ethical standards and guidelines form
          a foundation of integrity and assist the thought process to produce
          the best result in a situation. Use them as a guide in your Healing
          Ministry to assist you in creating a better world both physically and
          also spiritually.
        </p>
        <p>
          The following Guidelines have within them the Four Great Principles of
          the Ancestors.
        </p>
        <h4>Guideline 1: First, Do Good</h4>
        <p>
          In the church, the Spirit of Truth Native American Church members
          strive to safeguard the rights and welfare of those with whom they
          interact, other affected persons, the welfare of all living things,
          and of the Earth itself. They follow the Way as the Peacemaker taught.
          With their interactions, they strive to first choose the "Good Path"
          of the Peacemaker and avoid methods that might conceivably do harm,
          directly or as a side effect. Harmful interactions are avoided where
          reasonably possible. For any conflict that may occur, a member will
          strive to resolve it in the most responsible manner and with as little
          or no harm as they can in their emulation of the Peacemaker.
        </p>
        <p>
          Members and Ministers should use the educational materials, Sacred
          Writings, their Lodge Chapters, District Councils, and so forth to
          ensure that good comes from their actions and little or no harm
          ensues. A Medicine Person especially needs to be vigilant in
          protecting others from any misuse of their influence. While in service
          of others, the Medicine Person needs to hold the safety of those whom
          they are ministering to as the top priority.
        </p>
        <p>
          Each Spirit of Truth Native American member also needs to take into
          consideration their own physical, mental, and spiritual health and the
          possible effects of their well-being on those with whom they minister.
        </p>
        <h4>Guideline 2: Responsibility</h4>
        <p>
          Forming relationships of trust and honesty is the process for all
          Church Members. Members and especially Medicine People, should also
          hold themselves to a higher standard of ethical conduct and seek to
          educate all whom they work with. Clarifying any healing modality, be
          it physical or spiritual, and establishing informed consent with the
          person they are ministering to, is the responsibility of all members,
          Medicine Men, and Medicine Women. Medicine People also accept
          responsibility for who they are and for all of their actions.
        </p>
        <p>
          Spirit of Truth Native American Church Ministers make sure they
          consult with other Spirit of Truth Native American Church Medicine Men
          and Women, assist other professionals, educate themselves, maintain
          dignity and respect, and refer their clients to more qualified
          professionals or Healers when appropriate. They are attentive to the
          Ethical Code of Conduct for themselves and other Ministers in the
          Church. They strive to only speak positively about all others and
          avoid "evil speaking" in all its forms.
        </p>
        <p>
          Ministers and members regularly perform service, volunteer time, or
          support the Spiritual Community as appropriate. Contributing to the
          greater good is an honor and Spiritual duty.
        </p>
        <h4>Guideline 3: Integrity, Honor, and Service</h4>
        <p>
          In simple language, the Spirit of Truth Native American Church Healers
          hold to a Goodness Ethic. They strive to care for themselves, their
          family, their community, and the planet through cooperation. This
          leads to living a life of integrity and sustainability.
        </p>
        <p>
          Through education in all its forms, members, Medicine Men, and
          Medicine Women maintain competency in their Ministry and a connection
          to their Healing Services. They should only undertake those activities
          they can reasonably expect to fulfill. In their Medicine Bundle and
          alters they honor the Creator through the symbolism of the Sacraments,
          Healing Modalities, and so forth. It is an honor for the members to
          stand firm in their Spiritual Commitment by performing prayers, Bundle
          Openings, Bundle Closings, and Ceremonies in the course of their
          Healing Services they provide. This brings Honor to the Church and the
          member.
        </p>
        <p>
          Supporting other Spirit of Truth Native American Church members and
          Ministers in Goodness of Faith shows integrity and honor for the
          Sacred Healing Way. Members and Medicine People should only represent
          themselves honestly and make sure any recipient of their Ministry is
          fully informed that they are a member or Minister of the Church and of
          the services they are providing. Members should avoid all forms of
          "evil" and breaking the enforced established laws they are under.
          Members strive to keep their commitments, promises, and covenants.
        </p>
        <h4>Guideline 4: Respect</h4>
        <p>
          The Spirit of Truth Native American Church members, Medicine Men, and
          Medicine Women have respect for themselves, others, the environment,
          and their Healing Services. With honor and dignity, they find worth in
          the diverse connection and hold the life of all living things as
          Sacred. They are always mindful and reverent when the life of living
          things is taken. Everything is a part of Creation and members accept
          the responsibilities we have within the universal family and strive to
          honor "All My Relations" (mitakuye oyasin).
        </p>
        <p>
          Members respect the right of all individuals to live their life as
          they see fit. In the knowledge that some people may have physical,
          psychological, or spiritual weaknesses over ethnicity, gender, age,
          disability, sexual orientation, religion, language barriers, and so
          forth. Each member and minister of the Spirit of Truth Native American
          Church respects their own personal beliefs and values on any of these
          issues. However, Ministers shall not base their treatment of other
          people based on their own prejudices and shall always work within
          their knowledge base.
        </p>
        <h3 style={{ textAlign: "center" }}>
          The Ethical Code of Conduct of the Spirit of Truth Native American
          Church
        </h3>
        <p style={{ textAlign: "center" }}>
          (All Spirit of Truth Native American Church Members covenant to uphold
          this Code and to be bound by its precepts.)
        </p>
        <h3 style={{ textAlign: "center" }}>Title 1: Care of People</h3>
        <h4 style={{ textAlign: "center" }}>1.1 Duty and Authority</h4>
        <h5>1.1.1 Covenant People:</h5>
        <p>
          All Spirit of Truth Native American Church Members have been
          Spiritually Adopted by the Principal Medicine Chief of the Church in
          accordance with the Constitution. All members enter into a Covenant of
          (1) Natural Healing/Medicine is a part of my Spiritual Orientation and
          I wish to follow The Sacred Healing Way as set out by the Creator; (2)
          I will live by the practice of "First, Do Good"; (3) I will faithfully
          study the spiritual and traditional healing materials put out by the
          Spirit of Truth Native American Church, and their assigned, education
          under the direction of the President of the Church, by and through
          means he/she sees fit, and which is upheld by the Constitution and the
          Church's Ethical Code of Conduct; (4) I will dedicate time, talent,
          and resources, as the Spirit directs, to developing and forwarding the
          work of the Church and all of my payments or gifts made to the Church
          are free-will donations in accordance with the Constitution of the
          Church to be used to support the programs of the church and are given
          in the spirit of the Sacred Giveaway which is a bona fide Ceremony and
          Traditional Practice of the Spirit of Truth Native American Church;
          (5) As a member of the Church, I promise to follow the Ethical Code
          and Constitution of the Church. To make these Covenants and then to
          avoid following through with these promises, is a violation of the
          Spirit of Truth Native American Church Ethical Code of Conduct.
        </p>
        <p>
          (The Spirit of Truth Native American Church Members should pay special
          attention to fulfilling their promises and covenants. To make the
          promises implicit in the Request For Spiritual Adoption, and then to
          deny, or to avoid efforts to carry out those promises is a violation
          of the Spirit of Truth Native American Church's Ethical Code of
          Conduct.)
        </p>
        <h5>1.1.2 Members Authority:</h5>
        <p>
          When each Spirit of Truth Native American Church Member is Adopted,
          they receive the authority to qualify for any position held in the
          Church. They are followers of Yeshua, Spiritual Leaders, Traditional
          Leaders, and Church Family. They have the "Legal Right of Bishopric"
          practiced by our ancestors and passed on through direct lineal
          descent. This does not make the individual a "Bishop" of the Church,
          but only gives them the lineal authority that qualifies them for that
          position, should it be effectuated in them. The effectuation of the
          "Bishopric" depends on the Member's diligence and progress in the
          Sacred Healing Way. (For example, a member must obtain the calling of
          a Medicine Man or Medicine Woman in the Ministry to hold most
          leadership positions.) This is the reason that Spiritual Adoption is
          the method by which men and women are made part of the Spirit of Truth
          Native American Church Family. It is only through fulfilling their
          Covenant obligations as well as the required training that a Member
          can obtain any position in the Church. A Church "Bishop" or "High
          Priest" is known as a Chief or Medicine Man or Chieftess or Medicine
          Woman after our Holy Religious Order of the Spirit of Truth Native
          American Church. The President of the Church is the Presiding "High
          Priest" or Principal Medicine Chief also known as the Principal Stone
          Carrier of our Religious Order. All Church "Bishops" answer directly
          to the Principal Medicine Chief. The Spirit of Truth Native American
          Church Constitution restricts the dogmatism that dictates to
          individuals how their belief systems must look, but Spirit of Truth
          Native American Church Members understand that, if only because of the
          very legal nature of Spirit of Truth Native American Church
          membership, there is an important "Line of Authority," and they are
          careful to fully understand the authority extended to them. Spirit of
          Truth Native American Church Members know that to act outside one's
          authority is a violation of this Code, is very dishonorable, and can
          deleteriously affect, if not injure, all Spirit of Truth Native
          American Church members. To act outside one's authority cannot be
          rationalized as a "belief system" and no Spirit of Truth Native
          American Church Member ever tries to do so.
        </p>
        <h5>1.1.2a Authority of Medicine People Apprentice:</h5>
        <p>
          Every member who fulfills the requirements and is directed by the
          Spirit can become a Medicine Man or Medicine Woman. This is a
          ministerial position and as such, they are considered Ministers,
          Healers, Traditional Leaders, and so forth within the Spirit of Truth
          Native American Church. By virtue of their calling, they are
          Custodians of their Sacred Medicine Bundle (a Sacred Object), the
          Sacred Sacraments of the Church, and their Sacred Bundle Ceremony (a
          bona fide Spirit of Truth Native American Church Member Ceremony).
          This is a ministerial position that bridges the gap between the purely
          secular functions of the Church and the ecclesiastic functions. The
          Spirit of Truth Native American Church Medicine Man or Medicine Woman
          is distinguished by their dedication to the Church's Constitution and
          to the Sacred Healing Way.
        </p>
        <p>
          Members that have fulfilled their requirements and are Custodians of
          their Sacred Medicine Bundle (a Sacred Object), their Sacred
          Sacraments, and their Sacred Bundle Ceremony (a bona fide Spirit of
          Truth Native American Church Member Ceremony) but who have not met the
          Government's legal requirement of "Precedence of Competence" and have
          not been called as full Medicine Person by the President of the Spirit
          of Truth Native American Church, are Medicine Men Apprentices or
          Medicine Women Apprentices.
        </p>
        <p>
          As Medicine People Apprentices they have the full authority to perform
          all public Ordinances and Ceremonies under the direct direction of a
          Medicine Man or Medicine Woman. They can also perform all private
          Ordinances and Ceremonies for themselves and their immediate family
          under the direction of the Spirit. It is the sacred duty of every
          member to seek guidance from the Spirit, Educational Training, and
          follow the Church's Constitution and Ethical Code of Conduct when
          establishing their Ministry, Medicine Bundle, and Sacred Ceremonies.
          Every Medicine Person Apprentice must adhere to the Church's policies.
        </p>
        <h5>1.1.2b Authority of Medicine People:</h5>
        <p>
          Every member who fulfills the requirements and is directed by the
          Spirit can become a Medicine Man or Medicine Woman. This is a
          ministerial position and as such, they are considered Ministers,
          Healers, Traditional Leaders, and so forth within the Spirit of Truth
          Native American Church. By virtue of their calling, they are
          Custodians of their Sacred Medicine Bundle (a Sacred Object), the
          Sacred Sacraments of the Church, and their Sacred Bundle Ceremony (a
          bona fide Spirit of Truth Native American Church Member Ceremony).
          This is a ministerial position that bridges the gap between the purely
          secular functions of the Church and the ecclesiastic functions. The
          Spirit of Truth Native American Church Medicine Man or Medicine Woman
          is distinguished by their dedication to the Church's Constitution and
          to the Sacred Healing Way.
        </p>
        <p>
          Medicine People are members of the church that have been called by the
          President of the Church and who are significantly trained to satisfy
          the Government's legal requirement of "Precedence of Competence" so
          they can legally perform specific Ceremonies and Ordencenses for the
          public. It is the sacred duty of every member to seek guidance from
          the Spirit, Educational Training, and follow the Church's Constitution
          and Ethical Code of Conduct when establishing their Ministry, Medicine
          Bundle, and Sacred Ceremonies. It is the obligation of every Medicine
          Person to adhere to the Church's policies. Any Medicine Person
          fulfilling their Covenants and any required training may hold any
          position in the Church.
        </p>
        <h5>1.1.3 Education:</h5>
        <p>
          Each Spirit of Truth Native American Church Member has covenanted to
          study the education put out by the Church and to work to complete the
          curriculum published by the Church as they feel called by the Spirit.
          Education may be found through the Official Spirit of Truth Native
          American Church website, in manuals, Sacred Writings, Ceremonies, and
          so forth. All education may be changed, updated, and improved from
          time to time. Assignments, under the direction of the President of the
          Church, may be assigned to the Member from time to time.
        </p>
        <p>
          All Spirit of Truth Native American Church Members should make it a
          point to check their back office on the official Church website to get
          additions or updates on the educational material put out by the
          Church. The Church's education is a Course of Study that is above and
          beyond other educational training that Spirit of Truth Native American
          Church Members may obtain from other agencies.
        </p>
        <h5>1.1.4 Activity</h5>
        <p>
          In order to be protected under the law, one must establish a "Pattern
          of Behavior". This pattern of behavior can be easily established
          through activity in the Church via Ceremonies, Councils, Educational
          Training, and so forth. It is the duty of each Spirit of Truth Native
          American Church Member to manage their own activity, Covenant
          Obligations, educational training, and so forth to be protected under
          the law.
        </p>
        <h5>1.1.5 Sacred Giveaway</h5>
        <p>
          Supporting the Church so the Ministry may move forward is a Covenant
          that each Member makes. After adoption, continued offerings can be
          made through the Church's official website. Special offerings may be
          made to particular Departments, Districts, Lodges, the presiding Chief
          of any Ceremony, or directly to the President of the Church for
          distribution. It is the responsibility of the local Church Lodge
          Chapter, Departments, District, or presiding Chief, to keep accurate
          records of the Sacred Giveaway offering donations and expenses. They
          must report all offerings and send in the expense receipts to the
          Church Headquarters to be kept on file.
        </p>
        <h4 style={{ textAlign: "center" }}>1.2 Responsibility of Members</h4>
        <h5>1.2.1 Claims and Representation</h5>
        <p>
          Representation based on truth, faithfulness, or that are authorized,
          are considered correct. It is the responsibility of Members to
          correctly represent their work and the Mission of the Spirit of Truth
          Native American Church. To willfully fail to properly represent
          oneself or the Church is considered misrepresentation and is
          dishonorable.
        </p>
        <h5>1.2.2 Misrepresentation</h5>
        <p>
          Representations that are unauthorized, false, or misleading are
          considered injurious and a misrepresentation of the Church. If any
          misrepresentation is discovered, however well-intended, the Spirit of
          Truth Native American Church Member must take responsibility and
          correct such misrepresentations, regardless of how or by whom the
          misrepresentation is made.
        </p>
        <h5>1.2.3 Good Faith</h5>
        <p>
          When applying for Spiritual Adoption, each potential Spirit of Truth
          Native American Church Member, by Act of Good Faith, demonstrates a
          basic knowledge of the Three-Fold Mission and Covenants of the Church.
        </p>
        <h5>1.2.4 Constitution</h5>
        <p>
          Each Spirit of Truth Native American Church Member should demonstrate,
          in a reasonable manner, knowledge of the Church’s Constitution. When
          applying for Spiritual Adoption, each potential Spirit of Truth Native
          American Church Member should have a basic knowledge of the
          Constitution, Ethical Code of Conduct, and the Missions of the Church.
          However, members should obtain further knowledge of the Constitution
          through study and apply that knowledge to their actions and Ministry.
        </p>
        <h5>
          1.2.5 Spirit of Truth Native American Church Ethical Code of Conduct
        </h5>
        <p>
          Each Church Member should demonstrate, in a reasonable manner,
          knowledge of the Spirit of Truth Native American Church Ethical Code
          of Conduct. When applying for Spiritual Adoption, each potential
          member should have a basic knowledge of the Ethical Code of Conduct,
          the Constitution, and the Missions of the Church. Members should
          obtain further knowledge of the Ethical Code of Conduct and apply that
          knowledge to their actions and Ministry.
        </p>
        <h5>1.2.6 Peacemakers</h5>
        <p>
          The Spirit of Truth Native American Church Members’ dedication to the
          Missions of the Church is demonstrated by their Spiritual Adoption. As
          Members, we should further demonstrate, in a reasonable manner, by
          word and action that we are following the Way as Healers and
          Peacemakers. Resolving conflicts and using ethical decision-making by
          choosing to “First, Do Good”, as expressed by this Ethical Code, is
          highly honorable.
        </p>
        <h5>1.2.7 Spirit of Truth Native American Church Organization</h5>
        <p>
          Each Spirit of Truth Native American Church Member should strive to
          understand the scope and function of the main Organization of the
          Church and the boundaries that govern the behavior of the
          Ministers/Medicine Holders/Medicine People called to those
          Organizations. The Organization and its boundaries are found in the
          Constitution of the Church. Being called to a Stewardship in the
          Church is honorable and Members should be careful to pay appropriate
          respect to such Chiefs and support their calling through the Sacred
          Giveaway.
        </p>
        <h4 style={{ textAlign: "center" }}>
          1.3 Resolution of Conflicts and Ethics
        </h4>
        <h5>1.3.1 Domestic Law and Conflicts of Ethics: </h5>
        <p>
          Spirit of Truth Native American Church Members follow the teachings of
          Yeshua and are Peacemakers. We strive to comply with domestic law. If
          a Member ever experiences an ethical conflict between the internal
          rules of the Church and domestic law, he/she must contact the
          President of the Church immediately. Most domestic laws have special
          exemptions given to church members.{" "}
        </p>
        <h5>1.3.2 Informal Resolution:</h5>
        <p>
          If a Spirit of Truth Native American Church Member believes that
          another Member, regardless of the calling they may hold, has acted in
          violation of the Ethical Code of Conduct, and there is no threat of
          injury, they should take private consultation with the suspected
          offender wherein they discuss the suspected violation. A friendly and
          peaceful resolution of any perceived Ethical Code of Conduct violation
          is always preferred over a Formal Inquiry. If no informal resolution
          is forthcoming or if there is a perceived threat of injury, a Formal
          Inquiry into the violation shall be made by the Member that believes
          there is a violation.
        </p>
        <h5>1.3.3 Formal Inquiry and Resolution: </h5>
        <p>
          When an Informal Resolution has been unsuccessful, or there is a
          perceived threat of injury, or the Ethical Code of Conduct suspected
          violation is pertaining to more than one individual or the Spirit of
          Truth Native American Church in general, the Spirit of Truth Native
          American Church Members must bring the matter privately before a
          Formal Inquiry. If the suspected violation is pertaining to the Spirit
          of Truth Native American Church in General, the matter must be brought
          before the President of the Church. If the suspected violation
          pertains to a particular Member, Lodge Chapter, District, or
          Department the appropriate Council Chief will hear the matter in a
          Formal Inquiry. All Formal Inquiries are referred to as Disciplinary
          Councils.
        </p>
        <h5>1.3.3a Arbitration Resolution: </h5>
        <p>
          If all the Parties involved in the ethical violation agree to
          arbitration, the President of the Church will either act as Arbitrator
          or shall appoint one. All Spirit of Truth Native American Church
          Members that agree to arbitration in the resolution of the Ethical
          Code of Conduct complaint understand that the decision of the
          Arbitrator is final. All Members will respect and honor the
          Arbitrator's decision.
        </p>
        <h5>1.3.3b Council Resolution: </h5>
        <p>
          If all the Parties involved in the ethical violation do not agree to
          arbitration, the President of the Church will call for an appropriate
          Council to hear the matter. All Spirit of Truth Native American Church
          Members understand that the decision of the Council is final. All
          Members will respect and honor the Council's decisions.
        </p>
        <h5>1.3.3c Restitution: </h5>
        <p>
          Spirit of Truth Native American Church Members understand that the
          decision of the Arbitrator or Council may involve restitution. All
          Members will respect and honor the Plan of Restitution as set forth by
          the Arbitrator or Council.
        </p>
        <h5>1.3.3d Confidentiality: </h5>
        <p>
          Resolving Ethical Code of Conduct violations will require a careful
          examination of all evidence from the accused and the injured parties.
          Spirit of Truth Native American Church Members know that the
          confidentiality of these conflicts is paramount and care is taken to
          assure confidentiality. Unless written consent is obtained from all
          parties to release the findings of the conflict, confidentiality must
          be maintained by all parties involved in the conflict and resolution.
        </p>
        <h5>1.3.4 Truth: </h5>
        <p>
          All Spirit of Truth Native American Church Members understand that in
          the event they are called to answer to or participate in an
          Arbitration or Disciplinary Council, they are obligated to willingly
          participate. All Members know that only truthful reporting of all they
          understand concerning the matter shall be permitted. Failure to
          participate, be truthful, or avoid Arbitration or Disciplinary Council
          is itself a violation of the Spirit of Truth Native American Church
          Ethical Code of Conduct and is dishonorable.
        </p>
        <h5>1.3.4a Personal Witness:</h5>
        <p>
          All Spirit of Truth Native American Church Members understand that
          they may only give personal witness during Arbitration or Disciplinary
          Council. They may only speak for themselves, what they personally
          know, or about any evidence that came into their personal possession.
        </p>
        <h5>1.3.4b Personal Agency: </h5>
        <p>
          All Spirit of Truth Native American Church Members know that they
          shall not represent another Member. Any Member may act as a Proxy for
          another, but only to read a personal account of the situation. Any
          Proxy shall not suggest an opinion for and on behalf of another
          person.
        </p>
        <h3 style={{ textAlign: "center" }}>1.4 Church Mission</h3>
        <h5>1.4.1 Sacred Healing Way:</h5>
        <p>
          The Spirit of Truth Native American Church Members adhere to the
          principles of the Sacred Healing Way. The Constitution outlines these
          principles in greater detail but all Members should have a basic
          understanding of the Church's Missions. The Three-Fold Mission of the
          Church is for the improvement and enlightenment of the Body of the
          Church, for the work of the Ministry, and for the saving of the Living
          and the Dead. Other Church Missions include (1) acquiring truth; (2)
          self-determination and self-direction; (3) the competent use of all
          forms of Spirit-inspired traditional healing modalities to heal the
          individual, family, community, society, and the planet; (4) and the
          education of others on the Way.
        </p>
        <p>
          Every Spirit of Truth Native American Church Member is on the path to
          becoming a Healer and Minister associated with one or more of the
          elements of the Missions of the Church. Members declare their
          competence, or their desire and dedication to obtain competence in
          one, more, or all of the elements of the Missions of the Church.
        </p>
        <p>
          Spirit of Truth Native American Church Members should know the first
          level of competence is to complete the Basic Church Education and then
          strive to obtain the calling of Medicine Man or Medicine Woman. As a
          Medicine Person, they then become Ministers and Healers of the Church.
        </p>
        <h5>1.4.2 Ministering Under Your Limits of Competence:</h5>
        <p>
          The Healer of the Individual is any Spirit of Truth Native American
          Church Member who through the agency of the Creator, through the
          diligent fulfilling of their requirements, and has been called by the
          Spirit to assist in one or more Missions of the Church.
        </p>
        <p>
          All Spirit of Truth Native American Church Members and Medicine People
          understand that they may only provide healing services, education,
          Ceremonies, consulting, and so forth, for which they have been
          appropriately trained. Medicine People must have a full understanding
          of his/her level of competency, and a good-faith demonstration of
          their competency may be required. Good faith demonstration of
          competency is based on Church Education and Curriculum, transcripts,
          trainings, mentoring, experience which has been deemed equivalent, and
          so forth. All Spirit of Truth Native American Church Members
          understand they must provide disclosure of such competency when
          necessary.
        </p>
        <h5>1.4.2a Lack of Competence:</h5>
        <p>
          Whenever a Spirit of Truth Native American Church Member finds they
          are lacking in skill or competence in a given situation, he/she is
          careful to a) consult with a competent Spirit of Truth Native American
          Church Medicine Person, b) defer the service until further education,
          training, or research may be obtained, c) refer to competent Spirit of
          Truth Native American Church Medicine Person or other competent
          professional.
        </p>
        <h5>1.4.2b Competence Representation:</h5>
        <p>
          Spirit of Truth Native American Church Members know that additional
          education and training are necessary to establish competence in any
          specific healing modality or Sacramental Medicine. They should never
          give false representations of their competence. All representation of
          competence should be at least equal to the standards normally
          associated in society for that healing modality.
        </p>
        <h5>1.4.2c Crisis or Emergency Services:</h5>
        <p>
          In the event of a crisis or emergency, any Spirit of Truth Native
          American Church Members may assist, but only to the specific degree of
          their competence in the service rendered. The Domestic Good Samaritan
          Laws in every State are full of legal flaws. Any Member that offers
          services for which they have not established competence places
          themselves, their families, and all Spirit of Truth Native American
          Church in legal peril.
        </p>
        <h5>1.4.3 Maintaining Competence:</h5>
        <p>
          Spirit of Truth Native American Church Members understand that by
          Covenant they are required to continue their education in the Sacred
          Healing Way. Maintaining and continuing their education is mandatory.
          Failure to do so is a violation of the Church’s Ethical Code of
          Conduct.
        </p>
        <h5>1.4.4 Personal Judgment:</h5>
        <p>
          When making decisions, Spirit of Truth Native American Church Members
          use their best personal judgment based on their experience, training,
          education, scientific inquiry, and so forth. They understand that they
          may be called upon to explain their decision-making process.
        </p>
        <h4 style={{ textAlign: "center" }}>1.5 Interpersonal Relationships</h4>
        <h5>1.5.1 Belief Systems</h5>
        <p>
          The Spirit of Truth Native American Church Constitution is very clear
          that any Member shall not dictate the belief systems or healing
          modalities of any other person if such practices do not go against the
          Peacemaker’s teachings. Even though a Member may disagree, it is their
          responsibility to uphold and honor all belief systems and healing
          modalities that others may consider sacred.
        </p>
        <h5>1.5.2 Boundaries</h5>
        <p>
          All Spirit of Truth Native American Church Members should respect the
          boundaries of others. It is considered dishonorable to disrespect the
          boundaries of others and a violation of the Ethical Code of Conduct.
          When working with others, participating in Ceremony, Healing Services,
          Councils, and so forth, reasonable respect for others and their
          boundaries shall be maintained. All people must participate in the
          Ceremony, Council, Healing Service, and so forth of their own free
          will and choice.
        </p>
        <h5>1.5.3 Criticism</h5>
        <p>
          All Spirit of Truth Native American Church Members understand that
          when they criticize others in any manner of "evil speaking", they are
          not following the Sacred Healing Way. It is especially wrong, and a
          clear violation of the Ethical Code of Conduct, to spread rumors,
          criticize, and condemn others because of their belief in a practice or
          Ceremony. It is the responsibility and duty of any Spirit of Truth
          Native American Church Member to uphold the individual's right to
          follow Yeshua’s teachings to their understanding and worship their
          Creator according to the dictates of their own heart.
        </p>
        <h5>1.5.3a Solicitation</h5>
        <p>
          It is a violation of the Spirit of Truth Native American Church
          Ethical Code of Conduct, and dishonorable, to attempt to persuade a
          participant or leader of a Ceremony, Healing Service, Traditional
          Practice, and so forth, into a different service without the express
          permission of the Medicine Person in charge of the first Ceremony.
        </p>
        <h5>1.5.3b Coercion</h5>
        <p>
          It is a violation of the Spirit of Truth Native American Church
          Ethical Code of Conduct to use any form of pressure or coercion on
          individuals to remain in a Ceremony, Council, leadership role, and so
          forth. Service in all Spirit of Truth Native American Church
          Ceremonies, Traditional Practices, Healing Services, Councils, and so
          forth is entirely voluntary. The exception to this rule is for
          Ceremonies involving Sacraments that may inhibit or impair an
          individual's judgment. Only then may the individual under influence of
          a Sacrament be carefully guided to remain in the Ceremonial Area for
          their safety.
        </p>
        <h5>1.5.4 Informed Consent</h5>
        <p>
          When any Spirit of Truth Native American Church Member provides a
          service, Ceremony, traditional practice, and so forth, to any person
          they must disclose completely the type of service, what to expect,
          what to do in preparation, possible outcomes, as well as possible
          risks or side effects. Before the establishment of any such service,
          the person must give their consent based on being informed. Any
          services provided without informed consent are in violation of the
          Spirit of Truth Native American Church Ethical Code of Conduct.
          (Example: Anyone performing a healing massage should inform the
          receiver of the improvement reactions or detoxification effects a
          massage may release.)
        </p>
        <p>
          If for any reason a person is unable to give such informed consent,
          then consent must be obtained from a legal guardian or agent who is
          authorized to give such consent.
        </p>
        <h5>1.5.4a Simple Consent Form</h5>
        <p>
          Informed consent forms are often taken out of context and used as
          evidence in courts of law. Therefore, in order to protect our Members
          and Ministers, any informed consent forms ought to describe the
          consent of the person, not the content of the service, ceremony,
          sacrament, or sacred object.
        </p>
        <p>
          It is always advisable to also have on the consent form that the
          Member will be ministering to the individual as a Spiritual Guide.
          (Example: In the Church’s educational course material you will find an
          attachment containing the "Personal Health and Consent Form". If you
          read the paragraph above the individual’s signature line, you will see
          the informed consent of the person.)
        </p>
        <h5>1.5.5 Discrimination</h5>
        <p>
          All Spirit of Truth Native American Church Members are ministers of
          the Faith in their everyday life, gatherings, ceremony, traditional
          practice, working with Sacraments, working with Sacred Objects, and so
          forth. All members will use appropriate discretion in their Healing
          Ministry but will not discriminate based on religion, philosophical
          values, age, gender, race, ethnicity, socio-economic status,
          disability, sexual orientation, culture, language, and so forth.
          Discrimination by any Member is considered a violation of the Ethical
          Code of Conduct.
        </p>
        <h4 style={{ textAlign: "center" }}>1.6 First, Do Good</h4>
        <h5>1.6.1 Application:</h5>
        <p>
          A Member strives to live up to the “First, Do Good” code of conduct by
          making all decisions based on a risk/reward ratio, choosing the “Good
          Path” of the Peacemaker, and avoiding methods that might conceivably
          do harm, directly or as a side effect. If the desired benefit entails
          any degree of risk greater than those attendant with normal life
          activities, the Spirit of Truth Native American Church Members should
          seek other good alternatives. Services that may do harm must be
          avoided at all costs, and may only be seriously considered after all
          other avenues have been exhausted. (Example: Medical Practitioners
          most often perform hysterectomies without trying other proven natural
          healing alternatives. This would be a direct violation of our First,
          Do Good code since there are other highly successful proven
          alternatives.) Using wisdom, walking the Sacred Way of Yeshua, and
          being aware of the Safety of Others is paramount for any
          Member/Medicine/Healer in their life, leading any Native American
          Ceremony, Service, Healing Practice, and so forth.
        </p>
        <h5>1.6.1a Harm:</h5>
        <p>
          Harm means, for the purposes of this Ethical Code of Conduct, any
          injury to the physical, emotional, and spiritual body of an individual
          or the threat of such injury.
        </p>
        <h5>1.6.1b Application:</h5>
        <p>
          Any course of Healing Service which, in itself, is injurious, or has
          inherent in its practice the potential to do such harm to the
          participants as to cause them to lose in any degree the capacity to
          function normally (except with specific sacramental medicines given in
          ceremony), is harmful and is avoided by all Spirit of Truth Native
          American Church Members.
        </p>
        <p>Examples:</p>
        <p style={{ marginLeft: "30px" }}>
          a) The current Medical Treatment for gallstones is to remove the
          organ. Removal of the organ causes many different degrees of side
          effects. This is considered to be doing harm because not only life and
          bodily functions are threatened, but successful nonintrusive
          alternatives do exist. Any Medicine Person engaging in this action
          without first exhausting the alternatives would be in violation of the
          Ethical Code of Conduct.
        </p>
        <p style={{ marginLeft: "30px" }}>
          b) Fluctuations in hormone levels before, during, and after menses can
          cause significant distress for a woman. Since other alternatives have
          shown extremely successful results, the removal of the reproductive
          system is never an option in the Healing of such afflictions, unless
          first, all other successful alternatives have failed for this
          individual. Any Medicine Person engaging in this action without first
          exhausting the alternatives would be in violation of the Ethical Code
          of Conduct.
        </p>
        <h5>1.6.2 Sexual or Emotional Harassment:</h5>
        <p>
          Members and Ministers (Medicine Holders) of the Church should be
          respectful of others in all situations. Any demeaning or offensive
          physical advances, sexual solicitation, verbal or non-verbal sex or
          gender-associated behavior, and so forth is unbecoming of a member and
          ethically unbecoming of a Minister of the Church. This behavior is
          also considered injurious and is a violation of this Ethical Code of
          Conduct and should immediately be reported to the Church and the legal
          authorities.
        </p>
        <h5>1.6.3 Sexual Abuse:</h5>
        <p>
          Inappropriate physical contact can be injurious, mentally if not
          physically. This is not only a violation of the Ethical Code of
          Conduct but also a violation of your Covenant of First, Do Good. All
          Spirit of Truth Native American Church Members should be very careful
          to explain any practice that may result in inappropriate physical
          contact. (Inappropriate physical contact is any act leading to or
          associated with copulation.) Any Healing Service that may lead to
          inappropriate contact MUST have the express permission of all parties
          involved. Lack of permission is considered an act of sexual abuse and
          assault and should immediately be reported to the Church and legal
          authorities.
        </p>
        <h5>1.6.4 Cooperation:</h5>
        <p>
          Wherever appropriate, indicated in the Constitution and Ethical Code
          of Conduct, and in the best interest of the person requesting
          services, cooperation with other Spirit of Truth Native American
          Church Members, Medicine People, or competent professionals is
          mandatory. During the cooperation, all adequate safeguards for the
          privacy of all parties will be maintained.
        </p>
        <h4 style={{ textAlign: "center" }}>1.7 Confidentiality</h4>{" "}
        <h5>1.7.1 Responsibility of Confidentiality</h5>
        <p>
          Spirit of Truth Native American Church Members understand that they
          have a responsibility and obligation to the best interest of the
          person to whom they are providing religious services. Confidentiality
          is a facet of that best interest. Keeping in confidence all personal
          information, stored documentation, records, discussions, and so forth
          is paramount. Failure to do so could be construed as doing harm to the
          individual receiving service.
        </p>
        <h5>1.7.2 Limitations on Confidentiality</h5>
        <p>
          Spirit of Truth Native American Church Members understand that
          extenuating circumstances, such as a subpoena or other dictates of
          Law, sometimes place limitations on confidentiality. When a Spirit of
          Truth Native American Church Member finds such limitations within the
          scope of the religious service to be provided, complete disclosure of
          such limitations to the person to receive the service is required.
        </p>
        <h5>1.7.2a Informed Consent</h5>
        <p>
          Whenever a Spirit of Truth Native American Church Member embarks on
          providing religious services to any person, family, group,
          organization, and so forth, the Limits of Confidentiality are always
          discussed, along with Informed Consent.
        </p>
        <h5>1.7.2b Electronic Transmission</h5>
        <p>
          Electronic Transmission often runs the risk of violating
          confidentiality. If electronic transmission is to be used, Spirit of
          Truth Native American Church Members are careful to fully inform the
          person receiving the service as part of the Informed Consent Process.
        </p>
        <h5>1.7.2c Broken Confidentiality</h5>
        <p>
          If there is a legal or third-party reason that Confidentiality is to
          be broken, Spirit of Truth Native American Church Members are careful
          to inform the party receiving the service as part of the Informed
          Consent Process.
        </p>
        <h5>1.7.2d Recordings</h5>
        <p>
          Audio and or visual recording automatically brings the risk of
          violation of Confidentiality. Whenever Spirit of Truth Native American
          Church Members provide services that are recorded, they inform the
          person prior to providing the service as part of the Informed Consent
          Process.
        </p>
        <h5>1.7.3 Confidentiality Breach</h5>
        <p>
          If for any reason Confidentiality is to be breached, informed consent
          to do so must be obtained from the person receiving the service. This
          informed consent should be in writing. If this is not possible, verbal
          informed consent is reasonable. Written documentation of verbal
          consent is always preferable.
        </p>
        <h5>1.7.4 Confidential Information</h5>
        <p>
          When consulting with colleagues, Spirit of Truth Native American
          Church Members are careful to keep confidential all information about
          a person receiving services that might lead to the identification of
          the person. They may consult about the situation or circumstance, but
          no personal information, in the form of writing, education, research,
          or any other medium, is shared.
        </p>
        <h4 style={{ textAlign: "center" }}>1.8 Ceremonial Healing Services</h4>
        <h5>1.8.1 Suffering Relief:</h5>
        <p>
          Every Spirit of Truth Native American Church Member makes a good faith
          effort to be actively and appropriately engaged in the relieving of
          suffering of all kinds. Relieving the suffering of the Individual,
          Family, Community, Society, and the Planet is paramount to the
          Church's Healing Ministry.
        </p>
        <h5>1.8.2 Emergence:</h5>
        <p>
          Spirit of Truth Native American Church Members share the Principle of
          Emergence as the core foundation of all Ceremonial Healing Services.
          This core principle is based in the belief that all Healing Services
          consist of facilitating the shift from illness to wellness be that
          physical, emotional, or Spiritual.
        </p>
        <h5>1.8.3 Ceremony:</h5>
        <p>
          Spirit of Truth Native American Church Members understand the key
          importance of Ceremony in Church Healing Services. It is through our
          Religious Ceremonies that we are protected under the Law. Every Spirit
          of Truth Native American Church Healing Service falls under the
          dictates of a Sacred Medicine Bundle Ceremony or other bona fide
          Ceremony. Members are dedicated to this religious belief, and they are
          always vigilant in attending to their duty in this regard.
        </p>
        <h5>1.8.3a Bundle Ceremony:</h5>
        <p>
          The Spirit of Truth Native American Church Sacred Bundle Ceremonies,
          in all their forms, is an important foundation for the Members of the
          Church. Every Healer should strive to put together their Sacred Bundle
          containing items symbolizing their Healing Ministry and competency
          which they have received through personal education and the Spirit.
          The Sacred Medicine Bundle, Healing Sacraments, and the corresponding
          Medicine Bundle Ceremony assist to harmonize personal belief systems.
          Members should adhere to the Church’s educational training and their
          Spiritual knowledge of the Sacred Bundle and the Medicine Wheel. All
          symbols of the Medicine Wheel and the Sacred Bundle, as well as the
          materials, modalities, and sacred objects they represent, are
          considered Sacraments within the Church and should be respected as
          such.
        </p>
        <h4 style={{ textAlign: "center" }}>1.9 Informed Consent</h4>
        <h5>1.9.1 Information:</h5>
        <p>
          Informed Consent for Healing Services is an absolute necessity. Spirit
          of Truth Native American Church Members are careful to provide
          information concerning the service to be provided adequate to
          constitute, within reason, an understanding on the part of the person
          receiving the service of the kind and nature of the service to be
          provided and the level of competence of the person providing such
          service.
        </p>
        <h5>1.9.1a Confidentiality:</h5>
        <p>
          Spirit of Truth Native American Church Members see to it, through the
          process of Informed Consent, that the person receiving Healing
          Services understands, within reason, the concept of Confidentiality,
          and also the limitations to Confidentiality, if any, that apply to the
          specific Healing Service.
        </p>
        <h5>1.9.1b Spiritual Orientation:</h5>
        <p>
          Spirit of Truth Native American Church Members are careful to inform
          persons to whom they provide Healing Services that they have sought
          out a religious service. Such religious services are of a Spiritual
          nature and are focused on Natural Healing. Members should be extremely
          careful that every person for whom they provide religious services
          understands that they have requested the services of a member of the
          Church and that such services constitute for the Spirit of Truth
          Native American Church Member, as a Person under the Law, as an
          Ecclesiastic Body in General, and as a Church Entire, the very
          establishment and practice of their Religion.
        </p>
        <h4 style={{ textAlign: "center" }}>1.10 Public Statements</h4>
        <h5>1.10.1 Public Statements:</h5>
        <p>
          Spirit of Truth Native American Church Members are careful to make
          only those public statements that pertain specifically to their own
          Sacred Medicine Bundle and corresponding Medicine Bundle Ceremony, and
          the Symbols, Sacraments, Modalities, Performances, and Sacred Objects
          found therein. They also make public statements regarding the Church
          in general that they have appropriately learned from the Spirit of
          Truth Native American Church’s Constitution, Ethical Code, approved
          Education, Ceremonies, official publications, and the UNDRIP
          Declaration. Only those Members specifically authorized by the Great
          Council to do so may speak or act for and on behalf of all Members of
          the Spirit of Truth Native American Church, or Church Entire, in any
          capacity or any context.
        </p>
        <h4 style={{ textAlign: "center" }}> </h4>
        <h5>1.11.1 Records:</h5>
        <p>
          Spirit of Truth Native American Church Members understand that, in the
          event of any Court Action, standard practice business-type records
          become the property of the State or Federal Government and have often
          formed the very foundation of fallacious convictions against Natural
          Healers. Members are therefore very careful when they keep records of
          the services they provide to relieve suffering so that such records
          reflect accurately the Spiritual Nature of the service provided.
          Members should avoid using verbalisms common among Commerce
          Professions and commit themselves to re-scripting so that all records
          harmonize with the true nature of their Healing Ministry. (Example:
          Members of the Church do not charge for their services, but a Sacred
          Giveaway Donation may be suggested to support their Healing Ministry.
          Anyone charging fees for services classifies the service as commerce
          and therefore exempts the service from the legal protection that the
          Native American Church offers.)
        </p>
        <h5>1.11.1a Confidentiality of Records:</h5>
        <p>
          Spirit of Truth Native American Church Members should be good record
          keepers of their Healing Ministry. They should be careful to organize
          all records in such a manner as to represent their religion and to
          provide for the confidentiality of the person receiving services.
        </p>
        <h5>1.11.1b Duration of Records:</h5>
        <p>
          Spirit of Truth Native American Church Members should retain and
          safeguard records documenting Healing Services for that period of time
          which is prudent and customary to their Healing Ministry.
        </p>
        <h5>1.11.1c Disposal of Confidential Information:</h5>
        <p>
          Old records and confidential information are disposed of in a manner
          that does not potentially risk the Confidentiality of the person who
          received the service.
        </p>
        <h4 style={{ textAlign: "center" }}> 1.12 Fees and Sacred Giveaway</h4>
        <h5>1.12.1 No Fees Not Ever:</h5>
        <p>
          {" "}
          Spirit of Truth Native American Church Members charge no fees for
          their Healing Ministry. Rather, they may make suggestions of Donation
          or Offering amounts and gratefully receive such Offerings in return
          for the services they render. This Sacred Giveaway is clear in the
          Informed Consent Process and the Spirit of Truth Native American
          Church Members uses this term out of lack of a better alternative
          only. Any money, product, exchange, or so forth is considered a Sacred
          Giveaway by the Member, even when the person receiving the service may
          not.
        </p>
        <h4 style={{ textAlign: "center" }}> 1.13 Seminary Training</h4>
        <h5>1.13.1 Seminary:</h5>
        <p>
          A Seminary is a Spirit of Truth Native American Church Certified
          Institution. All Spirit of Truth Native American Church Members value
          education and should consider training from a Certified Institution
          when available. All Members will refrain from offering services for
          which they are not adequately trained.
        </p>
        <h5>1.13.1a Training:</h5>
        <p>
          When training is received from Agencies and Institutions other than
          the Spirit of Truth Native American Church or Certified Seminaries,
          all transcript records and proof of completion should be sent to the
          Church for approval and appropriate filing in the Individuals Physical
          File (IPF). Training from such agencies or institutions which
          reasonably meet the standards set by the Church or Chief of Seminary
          Services are deemed essentially equivalent to that provided by the
          Church or Certified Seminaries. Training from such agencies or
          institutions which do not reasonably meet the standards set by the
          Church or Chief of Seminary Services is taken individually and may be
          deemed equivalent in part. Spirit of Truth Native American Church
          Members take it upon themselves to be current in their knowledge of
          the Church and Seminary Standards and to constantly update their
          understanding of those Standards.
        </p>
        <h5>1.13.1b Continuing Education:</h5>
        <p>
          All Spirit of Truth Native American Church Members understand that
          continuing education is an absolute requirement and Covenant they have
          made. Also, each Spirit of Truth Native American Church Medicine
          Holder is careful to obtain at least forty hours of continuing
          education each year. This continued education is pertinent to
          Spiritual Development, Religious Services, or to the type and kind of
          Healing Ministry they provide. When the Spirit of Truth Native
          American Medicine Men and Women fail to obtain such continuing
          education, they understand that their status may be reduced or
          suspended altogether. Medicine Men and Women are careful to
          appropriately manage this aspect of their duty as Ministers of the
          Church and do not expect to be reminded to do so by their leadership.
        </p>
        <h5>1.13.1c Seminary and Equivalency Standards:</h5>
        <p>
          The standards for all Seminary Training will be set by the Chief of
          Seminary Services. Changes, additions, amendments, and so forth, to
          the Standards will be regularly updated on the Official Spirit of
          Truth Native American Church Website.
        </p>
        <h4 style={{ textAlign: "center" }}>Title 2: Care of the Earth </h4>
        <h4 style={{ textAlign: "center" }}>2.1 Sustainable Future </h4>
        <h5>2.1.1 Future:</h5>
        <p>
          Spirit of Truth Native American Church Members consider themselves a
          part of the pattern and web of life of the universe. As part of the
          Creator’s creations, they consider all of creation their Sacred
          Relations. Natural Resources do not exist only to be consumed. Spirit
          of Truth Native American Church Members understand that they are
          duty-bound to work to provide a future for coming generations.
        </p>
        <h5>2.1.2 Natural Resources:</h5>
        <p>
          Spirit of Truth Native American Church Members are conscious of the
          effect the unchecked exploitation of natural resources has had on the
          health of the planet. This unnecessary exploitation has affected the
          state of wellness of all people physically, mentally, and spiritually.
          Members should strive to find and implement ways to assist the
          individual, family, community, society, and the planet from this
          unhealthy consumerism.
        </p>
        <h5>2.1.3 Sacraments:</h5>
        <p>
          Spirit of Truth Native American Church Members should be careful to
          investigate the origin, ingredients, manufacturing, and so forth of
          the Sacraments and Sacred Objects they employ in their Healing
          Services. Striving to provide only Sacraments or Sacred Objects that
          use sound sustainable practices in their origin is highly honorable.
          Members should support manufacturers that do not merely take from the
          Earth, but rather, give back as much or more than they take.
        </p>
        <h4 style={{ textAlign: "center" }}>Title 3: Care of Society </h4>
        <h5>3.1.1 Council System:</h5>
        <p>
          The Spirit of Truth Native American Church Constitution outlines a
          system of governance for the Church. Members understand this system
          and use it to work to heal society through their faithful
          participation in their Lodge, District, Spirit of Truth Native
          American Church Community, and other Council-Taking opportunities
          within the Programs of the Church.
        </p>
        <h5>3.1.1a Duty:</h5>
        <p>
          Spirit of Truth Native American Church Members understand that they
          should not be commanded in all things. They are self-governing and as
          a child of the Creator, it is their duty to further the Creator's
          Work. Through diligent study of the Spirit of Truth Native American
          Church Constitution, Church Code, education materials, Sacred
          Scriptures, messages from the President of the Church, Department
          Chiefs, and so forth, they should apply their knowledge in projects
          and activities. Members know that it is a part of their duty to
          promote the Sacred Healing Way. It is also their duty to assist the
          Spirit of Truth Native American Church in growing and healing the
          world. One way Members can fulfill their duty is by faithfully
          participating in Gatherings, Ceremonies, General Councils, Great
          Councils, and so forth.
        </p>
        <h5>3.1.1b Spirit of Truth Native American Church Organization:</h5>
        <p>
          The Spirit of Truth Native American Church Constitution outlines the
          leadership of the Church. All Members share the responsibility to
          assure that no governance, regulation, council, and so forth, shall be
          established except upon the guidelines set forth in the Spirit of
          Truth Native American Church Constitution. No Member or Council of the
          Spirit of Truth Native American Church Members may purposefully
          circumvent the Church’s Constitution. To do so is dishonorable.
        </p>
        <h5>3.1.1.c Constitutional Councils:</h5>
        <p>
          The Spirit of Truth Native American Church Constitution authorizes
          Councils. Those established within the guidance of the Constitution
          are authorized by the Church. No other agency or authority may speak
          or act for or on behalf of the Spirit of Truth Native American Church.
        </p>
        <h5>3.1.1.d Authority of Councils:</h5>
        <p>
          The Great Council is the Authoritative Voice of the Spirit of Truth
          Native American Church Constitution. It is the only body within the
          Spirit of Truth Native American Church authorized to ratify, change,
          or amend the Spirit of Truth Native American Church Constitution. As
          such, the Great Council is the only Council or Authority within the
          Church that may have oversight over, or administration of matters
          pertaining to all the Spirit of Truth Native American Churches
          everywhere. The Great Council may appoint Chiefs to fill
          administrative positions and carry out the policies, procedures, and
          fulfill the objectives set by it, but such Chiefs rely upon the Great
          Council for authority and direction. They may act autonomously within
          their Stewardships, but they understand that their actions fall under
          the oversight of the Great Council and that they may be countermanded
          in any decision, and/or replaced by that Council. Every Spirit of
          Truth Native American Church Member shares a responsibility to sustain
          and assist the Great Council in good faith.
        </p>
        <h5>3.1.1.e Authority:</h5>
        <p>
          The Spirit of Truth Native American Church Constitution establishes
          the Authority within the Church. It is through the Special Services,
          Lodge Chapters, Districts, Great Councils, and the President of the
          Church that the Authority is given to authorize the carrying out of
          the Stewardship of the Church. No Member may bypass the line of
          authority. No Chief may dictate the activities of the Church without
          proper authority as stated in the Constitution. All Chiefs only have
          authority over their individual Stewardship.
        </p>
        <h5>3.1.1.f Resolving Conflicts:</h5>
        <p>
          If any internal conflict arises within any organization of the Church,
          it is the Chief's responsibility over the organization to resolve said
          conflict. If internal conflict cannot or will not be resolved within
          the organization, it is then the sole responsibility of the President
          of the Church to assist in the mediation of said conflict. If the
          President of the Church is unable to guide the matter to an
          appropriate resolution, then the President will call for a meeting of
          the Great Council to hear the matter. The majority decision of the
          Great Council is final!
        </p>
        <h5>3.1.1g Local Authority:</h5>
        <p>
          The Lodge Chapter Council is the Authoritative Voice of the Great
          Council in all Local Matters. The Lodge Chapter Council is to be
          established in accordance with the Spirit of Truth Native American
          Church Constitution and carried into the word. All conflicts or
          disputes pertaining to the local organization should be resolved in
          the Spirit of the Church’s Constitution. If any conflict or dispute
          cannot be resolved at the local level, the matter will be taken before
          the Chief of the District.
        </p>
        <h5>3.1.1h Limits Upon Lodge Chapter Council Authority:</h5>
        <p>
          Local Lodge Chapter Councils are authorized to function in their
          Stewardship by the authority of the District Chief. Each Principal
          Stone Carrier (PSC) is the Talking Feather of the Lodge Chapter
          Council and appoints the Itsipi Leader (First Advisor) and the Unipi
          Leader (Second Advisor) of that Council. The Lodge Chapter Council is
          authorized to appoint any Sub-Chiefs, and/or other Administrative
          Assistants, to assist in the efficient carrying out of the Unifying
          Purpose of the Lodge. All such Administrative Staff shall fall under
          the authority of their Chapter and shall answer to no other authority,
          where local matters are concerned. The PSC answers to the District
          Chief. No PSC of any other Lodge of the Spirit of Truth Native
          American Church may dictate or regulate the activities of other Lodge
          Chapters.
        </p>
        <h5>3.1.1i Limits on District Authority:</h5>
        <p>
          All local Lodge Chapters existing in a designated common area will
          fall under the Authority of the District Chief as defined in the
          Spirit of Truth Native American Church Constitution. The District is
          established with the intent to be beneficial for all the Lodge
          Chapters it oversees. District Councils are authorized to function in
          their Stewardship by the Sustaining Vote of the Great Council. The
          District Chief is authorized to appoint any Sub-Chiefs, and/or other
          Administrative Assistants, to assist in the efficient carrying out of
          the Unifying Purpose of the District and its Lodge Chapters. All such
          Administrative Staff shall fall under the authority of their District
          Chief and shall answer to no other authority, where district matters
          are concerned. The District Chief answers to the Great Council. No
          District Chief of any other District of the Spirit of Truth Native
          American Church may dictate or regulate the activities of other
          Districts.
        </p>
        <h5>3.1.2 Active Role of Members:</h5>
        <p>
          Spirit of Truth Native American Church Members should take an active
          role in their local Lodge Chapters and in the governance of the
          Church. If no local Lodge presence exists in their locale, the Members
          know that it is up to them to work to establish a Lodge presence. It
          is not the responsibility of the leadership of the Church to provide
          that presence for them. The growth of the Church is accomplished in
          many ways. When Spirit of Truth Native American Church Members invites
          their colleagues to investigate the Spirit of Truth Native American
          Church, they can establish an ever-growing Church Community. All
          members should feel free to invite, inform, share, and educate others
          about the Church and its Ceremonies in an honest and respectful
          manner.
        </p>
        <h5>3.1.3 Fellowship:</h5>
        <p>
          Spirit of Truth Native American Church Members strive to work to
          establish connections between their Lodge Chapter, District, Church,
          and others around the world. They should not be satisfied with being
          isolated and know that true security and happiness come through
          fellowship.
        </p>
        <h4 style={{ textAlign: "center" }}>Title 4 Sacred Sacraments </h4>
        <h5>4.1.1 Right of Choice:</h5>
        <p>
          Spirit of Truth Native American Church Members understand that it is
          the expressed goal of the U.S. Food and Drug Administration to
          integrate and incorporate Natural Medicine into Allopathy and that one
          of the strategies it employs to do this is to re-classify Natural
          Medicine as new Over the Counter Drugs, or as new Medical Device, over
          which it has regulator power. Consequently, with these actions, all
          Natural Medicine could be felonized by the FDA.
        </p>
        <p>
          The Spirit of Truth Native American Church Constitution recognizes the
          right of all its Members to choose their method, kind of medicine,
          Sacrament, Ceremony, and so forth, insofar as such things do not
          constitute or tend toward injury, or threat of injury. (It is highly
          advisable that such medicine, Sacrament, Ceremony, and so forth have a
          Traditional Practice but it is not required since we are a Living
          Church and seek out all good and truth.) This personal choice will not
          be denied in any way by the Councils. However, the President of the
          Church has the right to create and control the curriculum for the
          training of its Members.
        </p>
        <h5>4.1.1a Responsible Use:</h5>
        <p>
          Many substances from the Plant, Animal, and Mineral Kingdoms are known
          to be dangerous in certain quantities, even though in other quantities
          they are a natural and effective part of Ceremonial Healing. The
          Spirit of Truth Native American Church Members who use such
          Sacramental Gifts in their Ceremonial Healing are responsible to have
          sufficient knowledge to avoid injury thereby. For example, even though
          the FDA has classified the Essential Oil of Wintergreen as safe as a
          food additive, it can be harmful or fatal if not used wisely. It is
          the responsibility of the Member to understand the Sacraments and use
          them safely.
        </p>
        <h5>4.1.2 Guided Meditation or Journeying:</h5>
        <p>
          Many forms of Natural Healing and Spiritual Enlightenment involve the
          use of guided meditation or “journeying.” These Ceremonies can be
          performed with Sacred Sacraments, such as Ayahuasca, or without these
          Sacred Sacraments such as extended fasting to induce a Vision Quest.
          Any form of guided meditation, journeying, hypnosis, and so forth that
          is performed as a service to the general public is required to have a
          trained Medicine Person in charge that understands the benefits and
          risks of such Healing Services. Any Medicine Person who uses such
          powerful tools to cause a person to do or say something they would not
          be inclined to do or say under normal circumstances is in frank
          violation of the Spirit of Truth Native American Church Ethical Code
          of Conduct and it is considered very dishonorable. This control is
          most especially observed in regression strategies, where the
          appearance and subsistence of “False Memory Syndrome” have been
          positively linked to the Practitioner’s suggestions. Spirit of Truth
          Native American Church Medicine Men and Women should always be careful
          to use no suggestion that could cause harm and strive to First, Do
          Good in all of their interactions.
        </p>
        {/* gdfg////////////////////////////////////////
        .//
        //////////////

        /////////////////
        /////////
        
        //////////////////////*/}
        <h4 style={{ textAlign: "center" }}>Title 5 Enforcement</h4>
        <h5>5.1.1 Power to Enforce</h5>
        <p>
          Authority to act in a disciplinary capacity is vested in the
          Disciplinary Councils where actual violation, injury, threatened
          injury, and so forth has taken place. Disciplinary Councils are
          special convocations of the organization where the offense took place.
          Councils of the Lodge convoke a Disciplinary Council. Where no Lodge
          Chapter exists, or where the suspected violation may involve Lodge
          Chapter Council Members, the President of the Church may call upon
          other existing Lodge Chapters to fill the Council. The President may
          even call upon the District Counsel to establish the Disciplinary
          Council. All decisions of the Disciplinary Councils are final.
        </p>
        <h5>5.1.1a Scope of Decision</h5>
        <p>
          Councils are empowered to decide for or against the Offense as it has
          been reported and proven, and to either recommend the expulsion of the
          Offender by recommending Spiritual Divorcement to the President of the
          Church, or they may recommend a Restitution Plan to give the Offender
          an opportunity to make amends and to restore their good standing and
          status in the Spirit of Truth Native American Church.
        </p>
        <h5>5.1.1b Expulsion</h5>
        <p>
          Disciplinary Councils are authorized to recommend the expulsion only
          of any Offender when injury, to the degree defined in the Spirit of
          Truth Native American Church Constitution, and for which restoration
          cannot be reasonably expected, upon a person or persons has actually
          taken place, or when an Offender refuses to comply with a Restitution
          Plan. A Spirit of Truth Native American Church Member may be expelled
          from the Church only upon a Recommendation of Spiritual Divorcement by
          a Disciplinary Council. Upon receiving such a recommendation, the
          President of the Church will very personally consider the matter. The
          Spirit of Truth Native American Church Member can only be divorced or
          expelled from the Church Membership by and through a Funerary Ceremony
          which is only performed by the President of the Church. Such expulsion
          of a Spirit of Truth Native American Church Member may be equated to a
          loss of a child by the President.
        </p>
        <h5>5.1.1c Restitution Plan</h5>
        <p>
          Disciplinary Councils and Arbitrators must offer a Plan of Restitution
          to the Offender convicted of a violation of the Spirit of Truth Native
          American Church Ethical Code of Conduct, except where the violation
          results in injury to a person or persons for which restoration cannot
          reasonably be expected. The Offender must demonstrate a state of true
          repentance and a willingness to comply with the Restitution Plan. The
          Restitution Plan must be designed in such a way as to reasonably
          assure the likelihood of success. All Restitution Plans must include
          not less than six (6) months, but never more than two (2) years, of
          probation, in which the Member is not allowed to vote, participate in
          any Council Service, or hold any office of leadership. During such
          probation, the Offender must not represent themselves in any manner as
          a Minister, Medicine Person, or holder of any office in the Church.
          Once evidence of the correction of the violation has been established,
          full rights in the Church shall be returned. The two guiding
          principles upon which all Plans of Restitution are based must be
          first, severity, so that a repeat violation is avoided, and second,
          the restoration, insofar as reasonably possible, of the state of
          affairs that existed before the violation.
        </p>
        <h5>5.1.1d Participation Obligatory</h5>
        <p>
          Spirit of Truth Native American Church Members understand that they
          are duty-bound to respond to and participate with the Disciplinary
          Councils and that the decision of such Councils is final. Failure to
          attend or comply with a Disciplinary Council along with the
          Restitution Plan established by the said council is a violation of the
          Spirit of Truth Native American Church Ethical Code of Conduct and is
          in itself a basis for expulsion from the Church.
        </p>
        <h5>5.1.2 Amendment</h5>
        <p>
          Changes, additions, deletions, and any amendment of any kind to the
          Spirit of Truth Native American Church Ethical Code of Conduct falls
          within the Stewardship of the Great Council.
        </p>
      </Container>
      <Footer />
    </div>
  );
}
